import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../Mycontext";
import { message } from "antd";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import { scroller } from "react-scroll";
import Bidding from "./Bidding";
import { MdVerified } from 'react-icons/md';
import { AiFillStar } from 'react-icons/ai';
import { Link } from "react-router-dom";
import tempimg from '../../images/HireImg.png';
import { Popup } from "semantic-ui-react";
import Profile from "../../images/freelancer.png";
import img from '../../images/whiiteveryfied.png';
import Tooltip from '@mui/material/Tooltip';


function DetailView() {
  const location = useLocation();
  const detailjob = location.state;
  const { user, endPointUrl, Token, socket } = useContext(MyContext);
  const [balance, setBalance] = useState(0);
  const [show, setshow] = useState(false);
  const [Applied, setapplied] = useState(true);
  const Navigate = useNavigate();
  const [analytics, setAnalytics] = useState([]);

  const scrollToElement = () => {
    scroller.scrollTo("myElement", {
      duration: 300,
      delay: 0,
    });
  };

  const checkeditTable = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/job/bidding", {
        Token,
        UserID: user.id,
        JobID: detailjob.JobID,
      });
      if (data.status === true && data.data) {
        setapplied(true);
        setshow(true);
      } else {
        setapplied(false);
        setshow(false);
      }
    } catch (err) {
      console.log("Error", err)
      // setapplied(false);
      // setshow(false);
    }
  };

  const BidBalance = async () => {
    const { data } = await axios.post(endPointUrl + "/getuserByBidBalance", {
      Token,
      id: user.id,
    })
    console.log(data)
    setBalance(data.data.Bid_Balance);
  };

  const checkUser = () => {
    if (user) {
      if (user.Type === "Employer") {
        message.warning("Your are not a Freelancer ")
      } else {
        scrollToElement();
        balance ? setshow(true) : Navigate("/packages")
      }
    } else {
      Navigate("/login")
      message.warning("Login As Freelancer First")
    }
  }

  const Analytics = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/analytics", {
        id: detailjob?.employer?.id,
        Token
      });
      setAnalytics(data.data);
    } catch {
      message.error("Error while fetching analytics")
    }
  };

  useEffect(() => {
    if (user?.Type === 'Freelancer') {
      BidBalance();
    }
    if (detailjob?.navi) {
      socket.emit("Job_Expiry", {
        Type: "Invitation",
        FromID: detailjob?.Invite_To,
        ToID: detailjob?.Invite_By,
        Message: `${user.Name} ${user.Last_Name} has accepted your invitation to bid on the job post ${detailjob?.Title}`,
        IsRead: "false",
        ItemID: detailjob.JobID,
      })
    }
    checkeditTable();
    Analytics();
  }, [])

  function getLanguage(item) {
    // const languages = JSON.parse(item?.Language)

    let languages;
    try {
      languages = JSON.parse(item.Language);
    } catch (error) {
      return (<span>{item.Language}</span>);
    }
    return (
      <span>
        {languages?.Language1}
        {languages?.Language2 ? `, ${languages.Language2}` : ''}
        {languages?.Language3 ? `, ${languages.Language3}` : ''}
        {languages?.Language4 ? `, ${languages.Language4}` : ''}
        {languages?.Language5 ? `, ${languages.Language5}` : ''}
      </span>
    );
  };

  return (
    <div className='HowItWorks'>
      <div className="home-howitwork">
        <Link to="/">Home </Link>/{" "}
        <Link to="/UI & UX Design">Job Details</Link>
      </div>
      <span className="jobpostheading" style={{ backgroundColor: "#ceefe4", padding: "13px 10px 28px  40px" }}>
        <div>
          Job Details
          <p style={{marginTop:"20px"}} >Exploring job details is a crucial step in finding the perfect fit. It involves unraveling the specifics, understanding the core requirements, who can fulfill your needs effectively.</p>
        </div>

        <img src={tempimg} alt="" style={{ marginBottom: "-36px", height: "175px" }} />
      </span>
      <div className="jobProfileContainer" style={{ padding: "0", width: "100%" }}>

        <div className="jobContainer" style={{ padding: "0" }}>
          <div style={{ padding: "20px 40px" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <h2 style={{ color: "#07b274" }}>
                {detailjob?.Title}
              </h2>
            </div>

            {/* <h4 style={{ margin: "0" }}>Detail Description</h4> */}
            <div dangerouslySetInnerHTML={{ __html: detailjob?.Description }} className="detail_conatiner"></div>
            <div style={{ marginTop: "20px", }}>
              {detailjob?.SkillsRequired?.split(",").map((skill) => {
                return skill.length > 0 ? (
                  <span style={{
                    fontWeight: "500",
                    fontSize: "13px",
                    background: "transparent",
                    border: "1px solid #d4d4d4",
                    color: "#5a5a5a",
                    padding: "5px 18px",
                    margin: "5px",
                    borderRadius: "6px",
                    display: "inline-block",

                  }}> {skill}</span>
                ) : null;
              })}
            </div>
          </div>
          <>
            <hr />
            <Bidding
              Balance={balance}
              detailjob={detailjob}
              show={show}
              Applied={Applied}
              checkUser={checkUser}
            />
          </>
        </div>

        <div className="sideComponent" style={{ margin: "30px 0", padding: 0, borderRadius: "22px", width: "40%", border: "none" }}>
          <div className="compo2">
            <div className="profileConatiner" style={{ position: "relative" }}>
              <div className="profile-details">
                <img src={img} alt="" style={{ filter: detailjob?.employer?.Verified_Profile == "Verified" ? "none" : "grayscale(1)", width: "26px", height: "auto", marginLeft: "auto", marginRight: "15px" }} />

                <img
                  src={
                    detailjob?.employer?.Photo === "" || detailjob?.employer?.Photo == null
                      ? Profile
                      : `${endPointUrl}/images/${detailjob?.employer?.Photo}`
                  }
                  alt=""
                />
                <span className="profilename" onClick={() => Navigate(`/employer-profile/${detailjob?.employer?.id}`)} style={{ cursor: "pointer" }}>{detailjob?.employer?.Name}
                </span>

                <span className="profileDesignation">
                  {detailjob?.employer?.Title}
                </span>

                <Tooltip title="View all Reviews">
                  <span className="rating"
                    style={{ paddingLeft: "8px", fontSize: "16px", paddingBottom: "9px", cursor: "pointer" }}
                    onClick={() => Navigate(`/user-profile/reviews/${user.Name + "-" + user.id}`)}
                  >
                    <AiFillStar className="featuredfreelancer-rating-star" />
                    {detailjob?.employer?.avg_Rating ? detailjob?.employer?.avg_Rating : 0} <p>({detailjob?.employer?.no_of_reviews ? detailjob?.employer?.no_of_reviews : 0} Reviews)
                    </p>
                  </span>
                </Tooltip>
              </div>

              <hr />

              <div className="rate-card" style={{ borderRadius: "0 0 20px 20px" }}>
                <h2 style={{ marginBlock: "auto",wordBreak:'break-word' }}>About Employer</h2>
                <p style={{ fontSize: "12px", color: "#606060" }}>{detailjob?.employer?.About}</p>
                <div>

                </div>
                <hr />
                <div className="user-details">
                  <p>Job Post</p>
                  <span>{analytics?.totalprojects}</span>
                </div>
                <div className="user-details">
                  <p>Completed Projects</p>
                  <span>{analytics?.completedProject}</span>
                </div>
                <div className="user-details">
                  <p>Ongoing Projects</p>
                  <span>{analytics?.ongoingprojects}</span>
                </div>
                <div className="user-details">
                  <p>Close Projects</p>
                  <span>{analytics?.expiredprojects}</span>
                </div>
                <div className="user-details">
                  <p>Project Award</p>
                  <span>{Math.floor(((analytics?.completedProject + analytics?.ongoingprojects) / analytics?.totalprojects) * 100)}%</span>
                </div>
                <div className="user-details">
                  <p>Average Project Payment</p>
                  <span>${analytics?.completedProject > 0 ? (Math.floor(analytics?.revenuegenerated / (analytics?.completedProject+ analytics?.ongoingprojects))) ? (Math.floor(analytics?.revenuegenerated / (analytics?.completedProject+ analytics?.ongoingprojects))) : 0 : 0}</span>
                </div>
                <div className="user-details">
                  <p>Total Spent</p>
                  <span>${analytics.revenuegenerated}</span>
                </div>
                <div className="user-details">
                  <p>Language</p>
                  {getLanguage(detailjob?.employer)}
                </div>

                <div className="user-details">
                  <p>Gender</p>
                  <span>{detailjob?.employer?.Gender}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
}

export default DetailView;

