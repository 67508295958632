import React, { useEffect, useState, useContext } from "react";
import Profile from "../images/profile.jpg";
import Frame from "../images/Frame.svg"
import dolar from "../images/dolar.png";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AiOutlineFilePdf, AiOutlineFileText, AiFillStar } from 'react-icons/ai';
import { MyContext } from "../Mycontext";
import { MdPictureAsPdf, MdVerified } from 'react-icons/md';
import Certificate from "./user-profile/Certificate/AddCertificate";
import { Popup } from "semantic-ui-react";
import img from '../images/veryfied.png';
import Tooltip from '@mui/material/Tooltip';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import certified from "../images/certified.svg"
import { FaLocationDot } from "react-icons/fa6";
import ModalViewDocument from "./ModalViewDocument";
import { MdCastForEducation } from "react-icons/md";


function UserProfile() {
  const { user, endPointUrl, Token, socket } = useContext(MyContext);
  const [item, setitem] = useState({});
  const [education, seteducation] = useState([]);
  const [Exp, setExp] = useState([]);
  const [PortData, setPortData] = useState([]);
  const [CertData, setCertData] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [takenTest, setTakenTest] = useState([]);
  const [open, setOpen] = useState(false);
  const [certificateOpen, setCertificateOpen] = useState(false);
  const [portfolioOpen, setPortfolioOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const { nameid } = useParams();
  const Navigate = useNavigate();
  let skills = item?.Skills ? item.Skills.split(",") : [];
  // let technology = item?.Technology ? item.Technology.split(',') : [];
  const getuser = async () => {
    let id = nameid.split("-")[1];
    try {
      const { data } = await axios.post(endPointUrl + "/getUserById", {
        Token,
        id,
      });
      setitem(data.data);

    } catch {
      console.log("error for geting the userProfile");
    }
  };
  const getUserEducation = async () => {
    let id = nameid.split("-")[1];
    try {
      const { data } = await axios.post(
        endPointUrl + "/user/getuserEducation",
        { UserID: id, Token }
      );
      seteducation(data.data)
    } catch {
      console.log('error at education api')
    }
  };
  const getExp = async () => {
    let id = nameid.split("-")[1];
    const { data } = await axios.post(endPointUrl + "/user/getuserExp", {
      UserID: id,
      Token,
    });
    setExp(data.data);
  };
  const getPortfolio = async () => {
    let id = nameid.split("-")[1];
    const { data } = await axios.post(endPointUrl + "/user/getportfolioByUserID", {
      Token,
      UserID: id
    });
    if (data.data != null) {
      setPortData(data.data);
    }
  }
  const Analytics = async () => {
    let id = nameid.split("-")[1];
    try {
      const { data } = await axios.post(endPointUrl + "/analyticsforfreelancer", {
        id: id,
        Token
      });
      setAnalytics(data.data);
    } catch {
      message.error("Error while fetching analytics")
    }
  };
  const getCertificate = async () => {
    let id = nameid.split("-")[1];
    const { data } = await axios.post(endPointUrl + "/user/getCertiByUserID", {
      Token,
      UserID: id
    });
    if (data.data != null) {
      setCertData(data.data);
    }
  }



  const getTests = async () => {
    const { data } = await axios.post(endPointUrl + '/getSkilltestforfreelancer', {
      Token,
      id: nameid.split("-")[1]
    })
    setTakenTest(data?.assesment);
  };

  useEffect(() => {
    if (socket) {
      socket.emit("ProfileViewed", {
        Type: "ViewProfile",
        FromID: user?.id,
        ToID: nameid.split("-")[1],
        Message: `Employer Viewed Your Profile`,
        IsRead: "false",
        ItemID: user?.id,
      })
    }
    window.scroll(0, 0);
    getuser();
    getUserEducation();
    getExp();
    Analytics();
    getPortfolio();
    getCertificate();
    getTests();
  }, []);


  function getLanguage(item) {
    // const languages = JSON.parse(item?.Language)

    let languages;
    try {
      languages = JSON.parse(item.Language);
    } catch (error) {
      return (<span>{item.Language}</span>);
    }
    return (
      <span>
        {languages?.Language1}
        {languages?.Language2 ? `, ${languages.Language2}` : ''}
        {languages?.Language3 ? `, ${languages.Language3}` : ''}
        {languages?.Language4 ? `, ${languages.Language4}` : ''}
        {languages?.Language5 ? `, ${languages.Language5}` : ''}
      </span>
    );
  }

  const handleOpen = (e, item) => {
    e.preventDefault();
    setOpen(true);
    setSelectedItem(item.Education_File);
  }

  return (
    <div className="HowItWorks">
      <div className="home-howitwork">
        <Link to="/">Home </Link>/{" "}
        <Link to="/freelancers">Freelancers</Link>/{" "}
        <Link to={`/user-profile/${item.Name}-${item.id}`}>{`${item.Name} ${item.Last_Name}`}</Link>

      </div>
      <div className="profile-Apart">
        <img
          src={
            item.Photo === "" || item.Photo == null
              ? Profile
              : `${endPointUrl}/images/${item.Photo}`
          }
          alt=""
        />

        <div className="user-name">
          <h2>{item.Name} {item.Last_Name}</h2>
        </div>
        <div className="user-reviews-address">
          <Tooltip title="View all Reviews">
            <span className="rating"
              style={{ paddingLeft: "8px", fontSize: "16px", paddingBottom: "9px", cursor: "pointer" }}
              onClick={() => Navigate(`/user-profile/reviews/${item.Name + "-" + item.id}`)}
            >
              <AiFillStar className="featuredfreelancer-rating-star" />
              {item.avg_Rating ? item.avg_Rating : 0} <p>({item.no_of_reviews ? item.no_of_reviews : 0} Reviews)
              </p>
            </span>
          </Tooltip>
        </div>
      </div>


      <div className="about-rate">
        <div style={{ width: "69%" }}>
          <div className="about">
            <b>About</b>
            <p>{item.About}</p>
          </div>
          <div className="education">
            <strong>Education</strong>
            <div className="all-education">
              <div className="education-conainer" style={{ width: '100%' }}>
                {education.map((item) => (
                  <div style={{ marginTop: '3%' }}>
                    <div style={{ display: 'flex', width: "100%" }}>
                      <div style={{ width: "17%" }}>
                        <a
                          // href={`${endPointUrl}/education/${item.Education_File}`}
                          target="modalOpen"
                          rel="noopener noreferrer"
                          // onClick={(e) => handleOpen(e, item)}
                        >
                          {item.Education_File?.substring(item.Education_File?.lastIndexOf(".") + 1) === "pdf"
                            ?
                            <div><MdPictureAsPdf style={{ width: '70px', height: "70px", cursor: 'pointer' }} /></div>
                            : <MdCastForEducation
                              style={{ width: "70px", height: "70px", borderRadius: "15%" }}
                              className="potfoliothumbail"
                            />}
                        </a>
                        {open && <ModalViewDocument type="education" open={open} setOpen={setOpen} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}

                      </div>

                      <div style={{ width: "83%" }}>
                        <div style={{ fontWeight: "bold" }}>{item.DegreeName} - {item.Year}</div>
                        <div className="edusubtitle" >{item.Univercity}</div>
                      </div>

                    </div>
                    <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>

                  </div> 
                ))}
              </div>
            </div>
          </div>

          <div className="education">
            <strong>Work & Experience</strong>
            <div className="educaion-container" style={{ marginTop: "5%" }}>
              {Exp.map((item) => (
                (item.Company && item.From) ? (
                  <div style={{ color: "#606563" }}>
                    <div style={{ fontWeight: "bold", color: "#000" }}>
                      {item.Company + "-" + item.Designation}
                    </div>

                    <div style={{ display: "flex", gap: "5px", fontSize: "12px" }}>
                      <div>
                        {item?.From?.slice(5, 7)}, {" "}
                        {item?.From?.slice(0, 4)} - {" "}
                        {item?.to === "Present" ? item?.to :
                          `${item.Current ? "Present" : item?.to?.slice(5, 7)},
                    ${item.Current ? "Present" : item?.to?.slice(0, 4)}`}
                      </div>
                      <div>|</div>
                      <div><FaLocationDot />{item.Location}</div>
                    </div>

                    <p className="edusubtitle">{item.Description}</p>

                    <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>
                  </div>
                ) : (null)
              ))}
            </div>
          </div>

          <div className="education" style={{ width: "70%" }}>
            <strong style={{ display: "flex", alignItems: "center" }}>
              Certificates
            </strong>

            <div>
              <div style={{ display: 'flex', width: '100%=px', flexWrap: 'wrap', gap: "10%" }}>
                {CertData.map((item, index) =>
                  item?.UserID ?
                    <div key={index} style={{ width: "45%", marginTop: '5%' }}>
                      <div style={{ display: 'flex', width: "100%" }}>
                        <div style={{ width: "35%" }}>
                          <a
                            href={`${endPointUrl}/certificates/${item?.Certificate_Image}`}
                            target="modalOpen"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.preventDefault();
                              setCertificateOpen(true);
                              setSelectedItem(item.Certificate_Image);
                            }}
                          >
                            {item?.Certificate_Image?.substring(item.Certificate_Image.lastIndexOf(".") + 1) === "pdf"
                              ?
                              <div><MdPictureAsPdf style={{ width: "100px", height: "100px", cursor: 'pointer' }} /></div>
                              : < img
                                src={`${endPointUrl}/certificates/${item.Certificate_Image}`}
                                style={{ width: "100%", height: "100px", borderRadius: "10%" }}
                                alt="cert"
                                className="potfoliothumbail"
                              />}

                          </a>
                          {certificateOpen && <ModalViewDocument open={certificateOpen} setOpen={setCertificateOpen} type="certificates" selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                        </div>

                        <div style={{ marginLeft: "3%", width: "65%", color: "#606563" }}>
                          <div><span style={{ color: "black", fontWeight: "bold" }}>{item?.Title}</span></div>
                          {/* <div style={{ fontSize: '12px' }}>{item?.Type}</div> */}
                          <div style={{ fontSize: '12px' }}>
                            {item?.Completion_Date?.slice(5, 7)}, {" "}
                            {item?.Completion_Date?.slice(0, 4)}
                            {item?.Completion_Expire_Date ? "- " : ""}
                            {item?.Completion_Expire_Date?.slice(5, 7)}
                           {item?.Completion_Expire_Date ? ", " : ""}
                            {item?.Completion_Expire_Date?.slice(0, 4)}
                          </div>
                          {/* {item?.Authorized_Certification && <div style={{ fontSize: '12px' }}>{item?.Authorized_Certification}</div>} */}
                          <div style={{ fontSize: '12px' }}>UID:<span>{item?.Certification_UID}</span></div>
                        </div>
                      </div>
                    </div> : null
                )}
              </div>
            </div>
            <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>
          </div>

          <div className="education">
            <strong style={{ display: "flex", alignItems: "center" }}>
              Portfolio
            </strong>

            <div style={{ marginTop: "3%" }}>
              {PortData.map((item, index) =>
                item?.UserID ?
                  <div key={index}>
                    <div style={{ display: 'flex' }}>
                      {/* <div>
                        {item.docs?.map((it, index) =>
                          <div key={index} className="potfolioConatiner" style={{ position: "relative" }}>
                            <a
                              href={`${endPointUrl}/portfoliodoc/${it.File_Upload}`}
                              rel="noopener noreferrer"
                              target="modalOpen"
                              onClick={(e) => {
                                e.preventDefault();
                                setPortfolioOpen(true);
                                setSelectedItem(it?.File_Upload);
                              }}
                            >
                              {it.File_Type === "png" ||
                                it.File_Type === "jpg" ||
                                it.File_Type === "jpeg" ||
                                it.File_Type === "gif" ||
                                it.File_Type === "webp" ||
                                it.File_Type === "jfif" ? (

                                <img
                                  src={`${endPointUrl}/portfoliodoc/${it.File_Upload}`}
                                  style={{ width: "120px", height: "140px", borderRadius: "10%" }}
                                  alt={it.File_Type}
                                  className="potfoliothumbail"
                                />
                              ) : it.File_Type === "pdf" ? (
                                <MdPictureAsPdf className="potfoliothumbail" style={{ width: "120px", height: "140px" }} />
                              ) : (
                                <AiOutlineFileText className="potfoliothumbail" style={{ width: "120px", height: "140px" }} />
                              )}

                            </a>
                            {portfolioOpen && <ModalViewDocument open={portfolioOpen} setOpen={setPortfolioOpen} type="portfoliodoc" selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}

                          </div>
                        )
                        }
                      </div> */}

                      <div style={{ marginLeft: '3%', color: "#606563" }}>
                        <div><span style={{ color: "black", fontWeight: "bold" }}>{item?.Title} - {item?.Type}</span>
                        </div>
                        <div className="edusubtitle">
                        {item?.Completion_Date ? new Date(item.Completion_Date).toLocaleDateString('en-GB') : ''}
                        </div>
                        <p className="edusubtitle">
                        {item?.Description}
                        </p>
                        <div className="btn-popular skills" style={{ margin: 0 }}>
                          {item.Skills?.length < 1 ? <button className="skill">.... ....</button> : null}
                          {item.Skills?.split(',').map((it, index) => {
                            if (index < 5) {
                              return (
                                <button key={index} className="skill" style={{ fontSize: "8px", padding: "4px", gap: "3px" }}>
                                  {it}
                                </button>
                              );
                            }
                          })}
                          {item.Skills?.split(',').length > 5 ? "+" : ""}
                          {item.Skills?.split(',').length > 5 ? item.Skills?.split(',').length - 5 : ""}
                        </div>

                        {item?.Project_Link && <div style={{ fontSize: "12px", textDecoration: "underline" }}><a href={item?.Project_Link} target="_blank">Project Link</a></div>}
                      </div>
                    </div>

                    <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>
                  </div> : null
              )}
            </div>
          </div>

        </div>
        <div className="skills-tech-container">
          <div className="rate-card" >
            <img src={img} alt="" style={{ filter: item.Verified_Profile == "Verified" ? "none" : "grayscale(1)", marginLeft: "auto", position: "absolute", top: "10px", right: "10px" }} />

            {/* <Popup
              trigger={item.Verified_Profile == "Verified" ? <MdVerified style={{ color: "rgb(7 178 116)", marginLeft: "auto", position: "absolute", top: "10px", right: "10px", fontSize: "25px" }} /> :
                <MdVerified style={{ color: "rgb(133 133 133)", marginLeft: "auto", position: "absolute", top: "10px", right: "10px", fontSize: "35px" }} />}
              content={item.Verified_Profile == "Verified" ? "Profile Verified" :
                item.Verified_Profile == "Pending" ? "Profile verification Pending" :
                  "Profile Unverified"}
            /> */}
            <div className="price-range">
              <img src={dolar} alt="" />
              <div className="hour-price">
                <span className="rate">Rate:</span>
                <span>${item?.HourlyRate} / hour</span>
              </div>
            </div>
            <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
            <div className="user-details">
              <p>Completed Projects </p>
              <span> {analytics.completedProject} </span>
            </div>
            <div className="user-details">
              <p>Success Rate</p>
              <span>{Math.round(analytics.successRate)}% </span>
            </div>
            <div className="user-details">
              <p>Language</p>
              {getLanguage(item)}

            </div>
            <div className="user-details">
              <p>Total Earnings</p>
              <span>${analytics.totalEarning}</span>
            </div>
            <div className="user-details">
              <p>Gender</p>
              <span>{item.Gender}</span>
            </div>
            <hr style={{ borderTop: '1px solid #f1eded' }}></hr>

            <div className="skills-card" style={{ margin: "10px 0px" }}>
              <h5>Skills</h5>
              <div className="skills-employee " >
                {skills.map((val) => (
                  <strong style={{ color: "#000000", borderRadius: "15px", border: "1px solid #c6bbbb87", padding: "5px 10px" }} key={val}>{val}</strong>
                ))}
              </div>
            </div>
          </div>
          {takenTest.length > 0 && <div className="rate-card">
            {takenTest.length > 0 &&
              <div style={{ fontSize: '20px' }}>
                <div><strong style={{ padding: 0 }}>Digilanxer Certified</strong></div>
                <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
              </div>
            }

            {takenTest.length > 0 && takenTest?.map((item) => {
              {
                return (
                  item.Result == "Pass" ?

                    <div className="skills-card" style={{ margin: "10px 0px", display: 'flex' }}>
                      <h5><img src={certified} style={{ color: "#07b273", width: "50px", }} />
                      </h5>
                      <div className="skills-employee " style={{ fontWeight: 'bold', marginLeft: "5px" }}>
                        Digilanxer Certified {item.userexam.SKT_Title} Expert
                      </div>
                    </div> : null)
              }
            })}
          </div>}

        </div>
      </div>



    </div >
  );
}

export default UserProfile;
