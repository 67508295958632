import React, { useContext } from "react";
import axios from 'axios';
import { MyContext } from "../../../Mycontext";
import deletebutton from '../../../images/delet2.png'
import { message } from 'antd';

function DeleteCertificate({ Certificate, getCertificate, getCertificateProfile }) {
    const { user, endPointUrl, Token } = useContext(MyContext);

    // Delete Certificate
    // const deleteCertificate = async () => {
    //     try {
    //         await axios.post(endPointUrl + "/user/deleteCertiDoc", {
    //             Token,
    //             C_Id: Certificate.C_Id
    //         });
    //         alert("Are Toy")
    //         message.success("Certificate Deleted");
    //         getCertificate();
    //         getCertificateProfile();
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }
    const deleteCertificate = async () => {
        // Ask for confirmation
        const confirmDelete = window.confirm("Are you sure you want to delete this certificate?");
        
        if (confirmDelete) {
            try {
                await axios.post(endPointUrl + "/user/deleteCertiDoc", {
                    Token,
                    C_Id: Certificate.C_Id
                });
                message.success("Certificate Deleted");
                getCertificate();
                getCertificateProfile();
            } catch (err) {
                console.log(err);
                message.error("An error occurred while deleting the certificate");
            }
        } else {
            console.log("Delete canceled");
        }
    };
    
    return (
        <>
            <div>
                <img id="del-btn" style={{height:'20px',width:'20px'}} src={deletebutton} alt="delete" onClick={() => deleteCertificate()} />
            </div>
        </>
    )
}

export default DeleteCertificate