import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { MyContext } from "../../../Mycontext"
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";
import moment from 'moment';
// import { useHistory } from "react-router-dom";
// import PaginationSection from "./PaginationSection";
// import ReactPaginate from 'react-paginate';
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form
} from "react-bootstrap";
import { Link } from "react-router-dom";


function SkillInvoice() {
    const { endPointUrl, Token, user } = useContext(MyContext);
    const [allDetails, setallDetails] = useState([]);
    const [sortOption, setSortOption] = useState("Latest");

    function generateInvoiceNumber(paymentDate, paymentId) {
        const date = new Date(paymentDate);
        const year = date.getFullYear().toString().slice(-2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to month because it's zero-based
        const invoiceCountStr = '001'; // You can start with '001' and increment it for each invoice
        const invoiceNumber = `INV${year}${month}${paymentId}`;
        return invoiceNumber;
    }

    // const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);
    const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [allDetails], [allDetails]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Invoice Number",
                accessor: "", // Access the payment date to generate the invoice number
                Cell: ({ row }) => (
                    <span > {generateInvoiceNumber(row.original.Payment_date, row.original.CertificatePayment_ID)
                    }</span >
                ),
            },
            {
                Header: "Amount",
                accessor: "Amount",
            },
            {
                Header: "Payment Date",
                accessor: "Payment_date",
                Cell: ({ value }) => (
                    <span>{moment(value).format('DD/MM/YYYY')}</span>
                ),
            },
            {
                Header: "Payment Reference",
                accessor: "PaymentReference",
            },
            {
                Header: "Action",
                accessor: user.Type == "Payment_ID",
                Cell: ({ row }) => (
                    <div>
                        {/* "Update" icon */}
                        <span
                            onClick={() => {
                                handleclick(row.original.CertificatePayment_ID, row.original.Payment_date)
                            }
                            }
                            style={{ cursor: "pointer", marginRight: "10px" }}
                        >
                            <Button style={{ fill: "#07b274" }}>View</Button>
                        </span>
                    </div >
                ),
            },
        ],
        []
    );

    const getPaidSkillTestInvoices = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/getAllCertificatePaymentByUserID", {
                Token, UserID: user.id
            });

            setallDetails(data.data);
            // console.log(data.data);
        } catch {
            console.error("getPaidSkillTestInvoices is not working");
        }
    };


    useEffect(() => {
        getPaidSkillTestInvoices();
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        gotoPage,
        pageCount,
        state,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 5 },
            sortBy: [{ id: "Payment_date", desc: sortOption === "Oldest" }],
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );
    const { globalFilter, pageIndex, pageSize } = state;

    // const handleSortChange = (event) => {
    //   setSortOption(event.target.value);
    // };


    const handleclick = (Id, paymentDate) => {
        const invoiceNumber = generateInvoiceNumber(paymentDate, Id);
        // const url = `/Invoice-Generator/${Id}/${invoiceNumber}`;
        const url = `/Test-Invoice-Generator/?invoiceNumber=${invoiceNumber}&Id=${Id}`;
        window.open(url, '_blank'); // Open the URL in a new tab
    };
    return (
        <>

            <Container fluid >
                <Row>
                    <Col lg="12">
                        <strong>Certificate fee invoices</strong>
                    </Col>
                    <Col lg="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">
                                    <Form style={{ display: "flex", justifyContent: "space-between" }} >
                                        <Col style={{ width: "100%" }} >
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                style={{
                                                    width: "100%",
                                                    padding: "8px",
                                                    border: " 1px solid #efefef",
                                                    borderRadius: "5px"
                                                }}
                                                aria-label="Search"
                                                value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                                            />
                                        </Col>
                                    </Form>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table className="table-hover table-striped " {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render("Header")}
                                                        <span>
                                                            {column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? " 🔽"
                                                                    : " 🔼"
                                                                : ""}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row) => {
                                            prepareRow(row);
                                            return (
                                                <tr  {...row.getRowProps()}>
                                                    {row.cells.map((cell) => {
                                                        return (<td  {...cell.getCellProps()}> {cell.render("Cell")}</td>)
                                                    })}
                                                </tr>
                                            );
                                        }

                                        )}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>



        </>
    );
}

export default SkillInvoice;