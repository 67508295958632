import React, { useContext } from "react";
import editbutton from "../../images/edit.png";
import deletebutton from "../../images/delet2.png";
import { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";
import { MyContext } from "../../Mycontext";
import add from "../../images/add.png";
import EditWorkExp from "./WorkExpEdit";
import { Country } from "country-state-city";
import Select from "react-select";
import { FaLocationDot } from "react-icons/fa6";

function WorkExp({ getExpProfile }) {
  const { endPointUrl, user, Token } = useContext(MyContext);
  const [formList, setFormList] = useState([]);
  const [exp, setExp] = useState([]);
  const [edititem, setedititem] = useState();
  const [showEdit, setshowEdit] = useState(false);
  const [expireDatetoggle, setExporeDatetoggle] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('');

  const [dateValid, setDateValid] = useState('')
  const [newExp, setnewExp] = useState({
    Company: "",
    Location: "",
    Designation: "",
    From: "",
    to: "",
    Description: "",
  });

  const [errorForm, setErrorForm] = useState({
    Company: "",
    Location: "",
    Designation: "",
    From: "",
    to: "",
    Description: "",
  });

  const [showmodel, setshowModel] = useState(false);

  const getExp = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getuserExp", {
      UserID: user.id,
      Token,
    });
    setExp(data.data);
  };

  useEffect(() => {
    getExp();
  }, [user]);

  const addExp = async (Exp, type) => {
    if (formList.length === 0 && Exp) {
      await axios.post(endPointUrl + "/user/userExp", {
        formList: [Exp],
        UserID: user.id,
        Token,
      });
    }
    else if (type === 'Submit') {
      await axios.post(endPointUrl + "/user/userExp", {
        formList,
        UserID: user.id,
        Token,
      });
    }
    else {
      await axios.post(endPointUrl + "/user/userExp", {
        formList: [...formList, Exp],
        UserID: user.id,
        Token,
      });
    }

    setFormList([])
    setnewExp({
      Company: "",
      Location: "",
      Designation: "",
      From: "",
      to: "",
      Description: "",
    })
    getExp();
    getExpProfile();
  };

  const handleEditExpModel = (Item) => {
    setshowEdit(true);
    let tem = exp.filter((item) => {
      return item.U_Ex_ID === Item.U_Ex_ID
    });
    setedititem(tem);
  };

  const handleExpModel = () => {
    setErrorForm({
      Company: "",
      Location: "",
      Designation: "",
      From: "",
      to: "",
      Description: "",
    })
    setnewExp({
      Company: "",
      Location: "",
      Designation: "",
      From: "",
      to: "",
      Description: "",
    })
    setFormList([])
    setshowModel(true);
    getExp();
  };
  const CloseModel = () => {
    setshowModel(false);
    setshowEdit(false);
  };

  const checkValidDate = (date) => {
    let flag = false;
    const enteredDate = new Date(date);

    if (exp.length > 0) {
      exp.some((item) => {
        const FromDate = new Date(item.From);
        enteredDate.setHours(0, 0, 0, 0);
        FromDate.setHours(0, 0, 0, 0);
        let ToDate;
        if (item.to === "Present") {
          ToDate = new Date();
          ToDate.setHours(0, 0, 0, 0);
        } else {
          ToDate = new Date(item.to);
          ToDate.setHours(0, 0, 0, 0);
        }
        console.log("entered Date : ", enteredDate);
        console.log("From Date", FromDate);
        console.log("To Date", ToDate);

        if (enteredDate >= FromDate && enteredDate <= ToDate) {
          flag = false;
          return true;
        } else {
          flag = true;
        }
      });
    } else {
      return true;
    }

    return flag;
  };

  const checkToValidDate = (date) => {
    let flag = false;
    const enteredDate = new Date(date);

    if (exp.length > 0) {
      exp.some((item) => {
        const FromDate = new Date(item.From);
        const enterFrom = new Date(newExp.From);
        enterFrom.setHours(0, 0, 0, 0)
        enteredDate.setHours(0, 0, 0, 0);
        FromDate.setHours(0, 0, 0, 0);
        let ToDate;
        if (item.to === "Present") {
          ToDate = new Date();
          ToDate.setHours(0, 0, 0, 0);
        } else {
          ToDate = new Date(item.to);
          ToDate.setHours(0, 0, 0, 0);
        }

        if (enterFrom <= FromDate && (enteredDate >= ToDate || (enteredDate >= FromDate && enteredDate <= ToDate))) {
          flag = false;
          return true;
        } else {
          flag = true;
        }
      });
    } else {
      return true;
    }
    return flag;
  }


  const handlesubmitExpModel = (e, value) => {
    e.preventDefault();
    const newFormErrors = {
      Company: "",
      Location: "",
      Designation: "",
      From: "",
      to: "",
      Description: "",
    };
    let showError = false;


    if (!newExp.Company && !newExp.Location && !newExp.Designation && !newExp.From && !newExp.to && !newExp.Description && value && formList.length >= 1) {
      addExp(newExp, 'Submit');
      setshowModel(false);
    }
    else {
      if (newExp.Company === null || newExp.Company === "") {
        showError = true;
        newFormErrors.Company = 'Please fill out this field!';
      } else {
        newFormErrors.Company = '';
      }
      if (newExp?.Company.length >= 50) {
        showError = true;
        newFormErrors.Company = 'Company Name less than 50 words';
      } else {
        newFormErrors.Company = '';
      }
      if (newExp.Location === null || newExp.Location === "") {
        showError = true;
        newFormErrors.Location = 'Please fill out this field!';
      }
      else {
        newFormErrors.Location = "";
      }
      if (newExp.Designation === null || newExp.Designation === "") {
        showError = true;
        newFormErrors.Designation = 'Please fill out this field!';
      } else {
        newFormErrors.Designation = '';
      }
      if (newExp.From === null || newExp.From === "") {
        showError = true;
        newFormErrors.From = 'Please fill out this field!';
      } else {
        newFormErrors.From = '';
      } 
      if (newExp.to === null || newExp.to === "") {
        showError = true;
        newFormErrors.to = 'Please fill out this field!';
      } else {
        newFormErrors.to = '';
      }
      if (newExp.to <= newExp.From) {
        showError = true;
        newFormErrors.to = 'To date greater that from date';
      } else {
        newFormErrors.to = '';
      }
      if (!newExp.Description || newExp.Description.trim() === "") {
        showError = true;
        newFormErrors.Description = 'Please fill out this field!';
      } else if (newExp.Description.length < 100) {
        showError = true;
        newFormErrors.Description = 'Minimum 100 characters required!';
      } else if (newExp.Description.length > 300) {
        showError = true;
        newFormErrors.Description = 'Maximum 300 characters allowed!';
      } else {
        newFormErrors.Description = '';
      }
      // if (newExp.Description === null || newExp.Description === "") {
      //   showError = true;
      //   newFormErrors.Description = 'Please fill out this field!';
      // } else if (newExp.Description.length < 100) {
      //   newFormErrors.Description = 'Minimum 100 character required!';
      // }
      // else if (newExp.Description.length > 300) {
      //   newFormErrors.Description = 'Maximum 300 character allowed!';
      // }
      // else {
      //   newFormErrors.Description = '';
      // }
      setErrorForm(newFormErrors);
      if (!showError) {
        setFormList([...formList, newExp]);


        if (!value) {
          setnewExp({
            Company: "",
            Location: "",
            Designation: "",
            From: "",
            to: "",
            Description: "",
          })
          setExporeDatetoggle(false)
        }

        if (value) {
          addExp(newExp);
          setshowModel(false);
        }
      }
    }
  };

  const deleteExp = async (U_Ex_ID) => {
    try {
      await axios.post(endPointUrl + "/user/deleteuserExp", { U_Ex_ID, Token });
      message.success("Delete Successful");
      getExp();
      getExpProfile();
    } catch {
      message.error("something Went wrong");
    }
  };

  const updateExp = async () => {
    try {
      await axios.post(endPointUrl + "/user/updateUserExp", {
        ...newExp,
        U_Ex_ID: edititem[0].U_Ex_ID,
        Token,
      });
      getExp();
      message.success("Update Successful");
    } catch {
      message.error("something went Wrong ");
    }
  }; 
 
  return (
    <div className="education">
      <strong style={{ display: "flex", alignItems: "center" }}>
        Work & Experience <img src={add} alt="" style={{ width: "20px" }} onClick={handleExpModel} />
      </strong>
      <div className="all-education">
        <div className="educaion-container">
          {exp.map((item) => (
            (item.Company && item.From) ? (
              <div style={{ color: "rgb(77 77 77)" }}>
                <div style={{ fontWeight: "bold", color: "rgb(77 77 77)" }}>
                  {item.Company + "-" + item.Designation}
                </div>

                <div style={{ display: "flex", gap: "5px", fontSize: "14px" }}>
                  <div>
                    {item?.From?.slice(5, 7)}, {" "}
                    {item?.From?.slice(0, 4)} - {" "}
                    {item?.to === "Present" ? item?.to :
                      `${item.Current ? "Present" : item?.to?.slice(5, 7)},
                    ${item.Current ? "Present" : item?.to?.slice(0, 4)}`}
                  </div>
                  <div>|</div>
                  <div><FaLocationDot />{item.Location}</div>
                </div>

                <p className="edusubtitle">{item.Description}</p>
                {/* <div className="edit-icons">
                  
                  <img id="edit-btn"  src={editbutton} alt="" onClick={() => handleEditExpModel(item)} />
                  <img id="del-btn" src={deletebutton} alt="" onClick={() => deleteExp(item.U_Ex_ID)} />
                </div> */}

                <div className="edit-icons" style={{position:"relative"}} >
                      <img id="edit-btn" src={editbutton} alt=""  style={{cursor:"pointer"}} onClick={() => handleEditExpModel(item)} />
                      <span className="hover-text" >Edit</span>
                      </div>
                      <div className="edit-icons mt-4" style={{position:"relative", marginTop:"10px"}} >
                      <img id="del-btn" src={deletebutton} alt="" onClick={() => {
                        if(window.confirm("Are you sure you want to delete?")){

                          deleteExp(item.U_Ex_ID)}}} />
                      <span className="hover-text">Delete</span>
                      </div>

                <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>
              </div>
            ) : (null)
          ))}
        </div>
        <div id="myModal" className={showmodel ? "model-open" : "model"}>
          <div className="modal-content" style={{ padding: 0 }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
              <span style={{ color: "#46c294", fontWeight: "600" }}>Add Experience</span>
              <span className="close" onClick={CloseModel}>
                &times;
              </span>
            </div>
            <div style={{ padding: "0 40px", marginTop: "30px" }}>
              <form
                className="model-field exp-form"
              >
                <div style={{ paddingInlineEnd: "20px" }}>
                  <div>
                    {" "}
                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                      <div>
                        <label htmlFor="Company">Company Name<span className="asterisk">*</span></label>
                        <small style={{ color: "#ec1c24" }}>{errorForm.Company}</small>
                        <input style={{ marginInlineEnd: "12%" }}
                          value={newExp.Company}
                          type="text"
                          name="Compnay"
                          className={errorForm.Company != "" ? "errorborder" : ""}
                          placeholder="Enter company name"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (!inputValue.trim()) {
                              return setnewExp({ ...newExp, Company: "" });
                            }
                            setnewExp({ ...newExp, Company: inputValue });
                          }}
                        />
                      </div>
                      <div>
                        <label htmlFor="Designation">Designation<span className="asterisk">*</span></label>
                        <small style={{ color: "#ec1c24" }}>{errorForm.Designation}</small>
                        <input
                          value={newExp.Designation}
                          type="text"
                          name="Designation"
                          className={errorForm.Designation != "" ? "errorborder" : ""}
                          placeholder="Enter your designation"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (!inputValue.trim()) {
                              return setnewExp({ ...newExp, Designation: "" });
                            }
                            setnewExp({ ...newExp, Designation: inputValue });
                          }}
                        />
                      </div>
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                      <div>
                        <label htmlFor="Location">Location<span className="asterisk">*</span></label>
                        <small style={{ color: "#ec1c24" }}>{errorForm.Location}</small>
                        <Select
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              width: '100%',
                            }),
                          }}
                          options={Country.getAllCountries()}
                          getOptionLabel={(options) => {
                            return options["name"];
                          }}
                          getOptionValue={(options) => {
                            return options["name"];
                          }}
                          value={
                            Country.getAllCountries().find(
                              (country) => country.name === newExp.Location
                            ) || null
                          }
                          onChange={(item) => {
                            setSelectedCountry(item);
                            setnewExp({
                              ...newExp,
                              Location: item.name,
                            });
                          }}
                        />
                      </div>

                      <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", flexDirection: "column", marginRight: "0%" }}>
                          <label htmlFor="From" style={{ display: "block", marginBottom: 0 }}>From<span className="asterisk">*</span></label>
                          <small style={{ color: "#ec1c24" }}>{errorForm.From}</small>
                          <input style={{ marginInlineEnd: "12%" }}
                            value={newExp.From}
                            type="date"
                            name="From"
                            placeholder="Enter Joining Date"
                            className={errorForm.From != "" ? "errorborder" : ""}
                            onChange={(e) => {
                              const enteredDate = e.target.value;
                              const currentDate = new Date().toISOString().split("T")[0]
                              const valid = checkValidDate(enteredDate)
                              if (valid) {
                                if (enteredDate > currentDate) {
                                  message.warning("Can Not Select Future Date!")
                                }
                                else {
                                  const extractedYear = enteredDate.split("-")[0].slice(0, 4);
                                  setnewExp({ ...newExp, From: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                }
                              }
                              else {
                                message.warning("Date is already falls between previous Experience")
                              }
                            }}
                            min="1900"
                            max="2099"
                          />
                        </div>


                        <div style={{ display: "flex", flexDirection: "column", marginRight: "6%" }}>
                          <label htmlFor="to" style={{ display: "block" }}>To<span className="asterisk">*</span></label>
                          <span style={{ display: 'flex', alignItems: 'center', gap: "6px", fontSize: '12px', marginTop: "6px" }}>
                            <input
                              type="checkbox"
                              style={{ width: "auto", margin: 0, padding: 0 }}
                              checked={expireDatetoggle}
                              onChange={() => {
                                const valid = checkToValidDate(new Date());
                                if (valid) {
                                  setExporeDatetoggle(!expireDatetoggle)
                                  setErrorForm({ ...errorForm, to: "" })
                                  setnewExp({ ...newExp, to: "Present" });
                                }
                                else {
                                  message.warning("Date is already falls between previous Experience")
                                }
                              }}
                            />Currently Employee</span>

                          <input
                            value={newExp.to}
                            readOnly={expireDatetoggle}
                            style={{ backgroundColor: expireDatetoggle ? "#eeeeee" : "" }}
                            type="date"
                            name="to"
                            placeholder="Enter left Date"
                            className={errorForm.to != "" ? "errorborder" : ""}
                            onChange={(e) => {
                              const toDate = new Date(e.target.value);
                              const fromDate = new Date(newExp.From);
                              const today = new Date();
                              const valid = checkToValidDate(toDate);
                              // alert("valid "+valid)
                              if (valid) {
                                if (toDate > today) {
                                  message.warning("Can Not Select Future Date")
                                }
                                else if (fromDate < toDate) {
                                  setnewExp({ ...newExp, to: e.target.value });
                                } else {
                                  message.warning("Invalid Entry")
                                }
                              }
                              else {
                                message.warning("Date is already falls between previous Experience")
                              }
                            }}
                            min="1900"
                            max="2099"
                          />
                          <small style={{ color: "#ec1c24" }}>{errorForm.to}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="description">Description<span className="asterisk">*</span></label>
                    <small style={{ color: "#ec1c24" }}>{errorForm.Description}</small>
                    <textarea style={{ width: "97.5%" }}
                      value={newExp.Description}
                      name="Description"
                      id="description"
                      cols="50"
                      rows="5"
                      className={errorForm.Description != "" ? "errorborder" : ""}
                      placeholder="Enter description"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (!inputValue.trim()) {
                          return setnewExp({ ...newExp, Description: "" });
                        }
                        setnewExp({ ...newExp, Description: inputValue });

                      }}
                    ></textarea>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                  <input type="reset" className="btn" onClick={(e) => handlesubmitExpModel(e, false)} value={'Add Experience'} style={{ width: "20%", backgroundColor: "#e3e3e3", color: "#000" }} />
                  <input type="submit" className="btn" onClick={(e) => handlesubmitExpModel(e, true)} value={"Submit"} />
                </div>
              </form>
              <div style={{ maxHeight: '200px', overflowY: "scroll" }}>
                {formList?.map((value, index) => (
                  <div key={index} style={{ padding: "0 40px", marginTop: "30px", marginBottom: "30px", display: 'flex', gap: '10px' }}>
                    <span>
                      <strong>Company</strong>:
                      {value.Company}
                    </span>
                    {/* <br /> */}
                    <span>
                      <strong>Location</strong>:
                      {value.Location}
                    </span>
                    {/* <br /> */}
                    <span>
                      <strong>Designation</strong>:
                      {value.Designation}
                    </span>
                    {/* <br /> */}
                    <span>
                      <strong>From</strong>:
                      {value.From}
                    </span>
                    {/* <br /> */}
                    <span>
                      <strong>to</strong>:
                      {value.to}
                    </span>
                    {/* <br /> */}
                    <span>
                      <strong>Description</strong>:
                      {value.Description}
                    </span>
                    {/* <hr /> */}
                  </div>

                ))}
              </div>

            </div>

          </div>
        </div>
        {showEdit ?
          <EditWorkExp data={edititem[0]} getExp={getExp} CloseModel={CloseModel} />
          : null}
      </div >
    </div >
  );
}

export default WorkExp;
