import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CardComponentWithStyles.css';

function getCard(partners, endPointUrl) {
    const navigate = useNavigate();

    if (partners.length > 0) {
        return partners.map((item) => (
            <div className="inner-card">
                <div className="img-container">
                    <img
                        src={`${endPointUrl}/images/${item.Logo}`}
                        className="img-style card-img-top"
                        alt="..."
                    />
                </div>
                {/* <div className="card-body " style={{ marginTop: "-20px" }}>
                    <h5 className="card-title">{item.CompanyName.charAt(0).toUpperCase() + item.CompanyName.slice(1)}</h5>
                </div> */}
            </div>
        ));
    } else {
        return <div>No Partners Yet...</div>;
    }
}

const CardComponentWithStyles = ({ partners, endPointUrl }) => (
    <>
        <div className='card-container'>
            {getCard(partners, endPointUrl)}
        </div>
    </>
);

export default CardComponentWithStyles;
