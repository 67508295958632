import React from 'react'
import Hire from '../images/Hire.png'
import arrow from '../images/right-arrows.png'
function HireEmployee() {
  return (
    <div className="SignUpEmployee">
      <h1>Hire</h1>
      <p className="sign-up-content">
        Choose the best Freelancers in 3 easy steps !
      </p>
      <div className="sign-up-content-img">
        <div className="sign-up-img"><img src={Hire} alt="" /></div>
        <div className="sign-up-points">
          <div className="sign-up-points-numbers">
            <div className="number">1</div>
            <div className="content"><h3>Evaluation</h3>
              <p>Look through the Quotes you've received while checking out the profiles of the Freelancers to see their performance, reviews, portfolios, and earnings figures.</p>
            </div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">2</div>
            <div className="content"><h3>Agreement </h3>
              <p>Choose the freelancer(s) you want to hire, and then agree on the project's parameters, payment schedule, and completion date.</p></div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">3</div>
            <div className="content"><h3>Hiring </h3>
              <p>As soon as you hire a Freelancer, begin working with them in a designated WorkRoom.</p>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default HireEmployee