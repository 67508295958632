import React, { useEffect, useState, useContext } from "react";
import { message } from "antd";
import { MyContext } from "../Mycontext";
import axios from "axios";
import manage from "../images/manage.png";
import comma from '../images/comma.png'
import { Link } from 'react-router-dom';
import { IoAddCircleOutline } from "react-icons/io5";
function reviewContainer() {
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [Reviews, setReviews] = useState([])
    const [formToggle, setFormToggle] = useState(false);
    const [formData, setFormData] = useState({
        GlobalReview_Name: '',
        GlobalReview_Date: '',
        GlobalReview_Review: '',
        Stars: '',
        GlobalReview_Review_Status: 'Pending',
    });

    const addTestimonial = async (e) => {
        e.preventDefault();
        if (formData.GlobalReview_Name == '' && formData.GlobalReview_Date == '' && formData.GlobalReview_Review == '' && formData.Stars == '') {
            return message.warning("Fill alll Filed")
        }
        try {
            const response = await axios.post(endPointUrl + "/admin/globalReviewCreate", {
                Token,
                ...formData
            });
            if (response.data.data) {
                message.success("Review added successfully")
                setFormData({
                    GlobalReview_Name: '',
                    GlobalReview_Date: '',
                    GlobalReview_Review: '',
                    Stars: '',
                    GlobalReview_Review_Status: 'Pending',
                });
            } else {
                message.warning("Error adding Review")
                console.log("Error adding Review:", response.data.message);
            }
        } catch (error) {
            console.log("Error adding testimonial:", error);
        }
    }


    const getReviews = async () => {
        const { data } = await axios.post(endPointUrl + '/admin/globalReviewfind', { Token, Status: "Active" });
        setReviews(data.data);
    }
    useEffect(() => {
        getReviews();
    }, [])
    return (
        <div className='HowItWorks' style={{ minHeight: "auto", height: '200vh', display: 'flex', flexDirection: "column" }}>
            <div>
                <div className="home-howitwork">
                    <Link to="/">Home </Link>/{" "}
                    <Link to="/freelancers">Reviews</Link>
                </div>
                <div style={{ margin: "20px 4%" }}>
                    <span className="jobpostheading" style={{ backgroundColor: "#ceefe4" }}>
                        <div>
                            Grow your brand with Digilanxer's user review
                            <p> Discover real user opinions with Digilanxer's professional reviews.</p>
                        </div>

                        <img src={manage} alt="" style={{ marginTop: "20px" }} />
                    </span>
                </div>
                <div className="testimonial-form-btn">
                </div>

            </div>
            {formToggle &&
                <div className="testimonial-form">

                    <form onSubmit={(e) => addTestimonial(e)}>
                        <div className="testoimonial-form_group">
                            <span style={{ width: "100%" }}>
                                <label className="testimonial-form-lebel" htmlFor="name">Name:</label>
                                <input
                                    className="testimonial-form-input"
                                    placeholder="Enter name"
                                    type="text"
                                    name="name"
                                    value={formData.GlobalReview_Name}
                                    onChange={(e) => setFormData({ ...formData, GlobalReview_Name: e.target.value })}
                                />
                            </span>

                        </div>
                        <div className="testoimonial-form_group">
                            {/* <span style={{ width: "100%" }}>
                                <label className="testimonial-form-lebel" htmlFor="rating">Rating:</label>
                                <input
                                    className="testimonial-form-input"
                                    placeholder="Rating"
                                    type="number"
                                    min='0'
                                    max='5'
                                    name="rating"
                                    value={formData.Stars}
                                    onChange={(e) => setFormData({ ...formData, Stars: e.target.value })}
                                />
                            </span> */}
                            <span style={{ width: "100%" }}>
    <label className="testimonial-form-lebel" htmlFor="rating">Rating:</label>
    <input
        className="testimonial-form-input"
        placeholder="Rating"
        type="number"
        min='0'
        max='5'
        name="rating"
        value={formData.Stars}
        onChange={(e) => {
            const value = Math.max(0, Math.min(5, Number(e.target.value))); // Ensures the value is between 0 and 5
            setFormData({ ...formData, Stars: value });
        }}
    />
</span>


                            <span style={{ width: "100%" }}>
                                <label className="testimonial-form-lebel" htmlFor="published_date">Published Date:</label>
                                <input
                                    className="testimonial-form-input"
                                    placeholder="Date"
                                    type="date"
                                    name="date"
                                    value={formData.GlobalReview_Date}
                                    onChange={(e) => setFormData({ ...formData, GlobalReview_Date: e.target.value })}
                                />
                            </span>

                        </div>
                        <div className="testoimonial-form_group">
                            <span style={{ width: "100%" }}>
                                <label className="testimonial-form-lebel" htmlFor="review">Review:</label>
                                <textarea
                                    className="testimonial-form-textarea"
                                    placeholder="Enter your review here"
                                    as="textarea"
                                    name="review"
                                    value={formData.GlobalReview_Review}
                                    onChange={(e) => setFormData({ ...formData, GlobalReview_Review: e.target.value })}
                                ></textarea>
                            </span>

                        </div>
                        <button className="btn-outline" type="submit">Submit</button>
                    </form>


                </div>
            }

            <div className='review_container_box' >
                <div className="rectangle-parent-7" style={{ alignItems: "center", justifyContent: "center" }}>
                    <IoAddCircleOutline style={{ fontSize: "10vw", color: "#808080fc", rotate: formToggle ? "45deg" : "0deg", transition: "all 0.3s linear" }} onClick={() => setFormToggle(!formToggle)} />
                    <p className="reviews">Share Your Reviews</p>
                </div>
                {Reviews?.map((item, index) =>
                    <div className="rectangle-parent-7" key={index}>
                        <img className='comma' src={comma} alt="Png File - Double Quotes Png@seekpng.com" />
                        <p className="reviews">
                            {item?.GlobalReview_Review}
                        </p>
                        <h2 className="aname">{item?.GlobalReview_Name}</h2>
                        <div className="star-2">
                            <i data-star={item?.Stars}></i>
                        </div>
                    </div>)}
            </div>

        </div >

    )
}

export default reviewContainer;