import React, { useEffect, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { CLIENT_ID } from '../../Config/Config'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import moment from "moment";
import { message } from 'antd';
import { MyContext } from "../../Mycontext";
import axios from 'axios';
import { FUNDING } from "@paypal/react-paypal-js";
import { useNavigate, Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import PaymentModal from './PaymentModal';

const MilestonePayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { Token, endPointUrl, user, socket } = useContext(MyContext);
  const [show, setShow] = useState({
    show1: false,
    show2: false,
    show3: false
  });
  const [Orderdata, setOrderdata] = useState({
    ID: "",
    time: ""
  });
  const [BidID, setBidID] = useState();
  const [MID, setMID] = useState();
  const [Index, setIndex] = useState();
  const [minHours, setMinHours] = useState('');
  const [value, setValue] = useState(10);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [ProjectType, setProjectType] = useState(location.state.ProjectType === "Hourly" ? true : false);
  const [Reason, setReason] = useState('')
  const [showRejectionBox, setShowRejectionBox] = useState(false);
  const [BidAmount, setBidAmount] = useState(false);
  const [remainPayment, setRemainPayment] = useState(false);
  const [walletUse, setWalletUse] = useState(false);
  const [todayDate, setTodayDate] = useState('');
  const [oldReason, setOldReason] = useState('');


  const getTodayDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${day}-${month}-${year}`;
    setTodayDate(formattedDate);

  }

  console.log('location', location.state)

  const getBidData = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/getBidByID", {
        Token,
        Bid_ID: location.state.milestone[0].Bid_ID,
      });

      setMinHours(data?.data?.MinHours);
      setBidAmount(data?.data?.BidAmount);

      // if (location.state.milestone[location.state.milestone.length - 1].timeSpend > parseInt(data?.data?.MinHours)) {
      //   const remainingHrs = parseInt(location.state.milestone[location.state.milestone.length - 1].timeSpend) - parseInt(data?.data?.MinHours)
      //   setValue(parseInt(data?.data?.BidAmount) * parseInt(remainingHrs));
      // }
      // else {
      //   const remainingHrs = parseInt(data?.data?.MinHours) - location.state.milestone[location.state.milestone.length - 1].timeSpend
      //   const value = (parseInt(data?.data?.BidAmount) * remainingHrs);
      //   let amt = (parseInt(data?.data?.BidAmount) * parseInt(data?.data?.MinHours) - value)
      //   let per = (amt * 10) / 100
      //   amt = amt - per
      //   setValue(amt);
      //   console.log('setavule', amt)
      // }
    } catch (error) {
      console.log("ERROR GET MILESTONE FOR BID", error);
    }
  }

  console.log('aluve', value);

  const getIndex = () => {
    if (ProjectType) {
      getBidData();
    }
    for (let index = 0; index < location.state.milestone.length; index++) {
      const item = location.state.milestone[index];
      if (item.EmployerPaymentStatus === "Pending") {
        setIndex(index);
        break;
      }
    }
  }
  const createOrder = (amt, data, actions) => {
    // console.log(data);
    return actions.order.create({
      purchase_units: [
        {
          description: "Milestone",
          amount: {
            currency_code: "USD",
            value: value,
          },
        },
      ],
    }).then((orderID) => {
      setOrderID(orderID);
      return orderID;
    });
  };

  const updateHourlMile = async (id, time) => {
    try {
      const ind = 0;
      const a = (parseInt(value) * 10) / 100;
      const val = value - a;

      const { data } = await axios.post(endPointUrl + "/user/applypayment", {
        Token,
        EmployerID: user.id,
        M_ID: location.state.milestone[Index].M_ID || 0,
        PM_ID: location.state.milestone[Index].PM_ID,
        Payment_date: time,
        JobID: location.state.item.JobID,
        Bid_ID: BidID,
        Amount: value,
        EscrowedAmount: val,
        PaymentReference: id
      });
      message.success("Payment Successful");

      Swal.fire({
        title: "What do you want?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Continue Project",
        denyButtonText: `Close Project`
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.post(endPointUrl + "/project/updatemilestone", {
            Token,
            PM_ID: location.state.milestone[location.state.milestone.length - 1].PM_ID,
            MilstoneStatus: "Approved",
            FreelancerPaymentStatus: "Released",
            Amount: BidAmount * location.state.milestone[location.state.milestone.length - 1].timeSpend,
            EscrowedAmount: BidAmount * location.state.milestone[location.state.milestone.length - 1].timeSpend,
            FreelancerActualPaid: BidAmount * location.state.milestone[location.state.milestone.length - 1].timeSpend - ((BidAmount * location.state.milestone[location.state.milestone.length - 1].timeSpend * 10) / 100)
          })
          await axios.post(endPointUrl + "/project/createHourProjectMilestone", {
            Token,
            Bid_ID: BidID,
            P_ID: location.state.milestone[0].P_ID,
            Description: location.state.milestone[0].Description,
            Amount: location.state.milestone[0].FreelancerTotalPaid,
            MilstoneStatus: "Pending",
          });

          navigate(-1);
        } else if (result.isDenied) {
          await axios.post(endPointUrl + "/project/updatemilestone", {
            Token,
            PM_ID: location.state.milestone[location.state.milestone.length - 1].PM_ID,
            MilstoneStatus: "Approved",
            FreelancerPaymentStatus: "Released",
            Amount: BidAmount * location.state.milestone[location.state.milestone.length - 1].timeSpend,
            EscrowedAmount: BidAmount * location.state.milestone[location.state.milestone.length - 1].timeSpend,
            FreelancerActualPaid: (BidAmount * location.state.milestone[location.state.milestone.length - 1].timeSpend * 10) - (BidAmount * location.state.milestone[location.state.milestone.length - 1].timeSpend * 10) / 100
          })
          if (ind == (location.state.milestone.length - 1)) {
            await axios.post(endPointUrl + "/UpdateProjects", {
              Token,
              P_ID: location.state.milestone[0].P_ID,
              Project_status: "Semi_Completed",
            })
          }

          socket.emit("notificationJobAssign", {
            Type: "Project",
            FromID: user.id,
            ToID: user.id,
            Message: `Project is completed for job ${location.state.JobTitle} `,
            IsRead: "false",
            ItemID: location.state.item.JobID
          })
          message.success(`Project Completed Successfully`)
          navigate(-1);
        }
      });

    } catch (error) {
      message.error("Unsuccessful in Approving milestone")
      console.log('errorsssssssssssssss', error)
    }

  }

  // check Approval
  const onApprove = (data, actions) => {
    if (remainPayment) {
      return actions.order.capture().then(function (orderData) {
        setOrderdata({
          ID: orderData.id,
          time: orderData.create_time
        })
        updateHourlMile(orderData.id, orderData.create_time)
      })
    }
    else {
      return actions.order.capture().then(function (orderData) {
        setOrderdata({
          ID: orderData.id,
          time: orderData.create_time
        })
        setSuccess(true)

        if (location.state.milestone[0].MilstoneStatus === "Pending") {
          socket.emit("notificationJobAssign", {
            Type: "Project",
            FromID: user.id,
            ToID: location.state.item.UserID,
            Message: `${user.Name + " " + user.Last_Name} approve your bid for job post ${location.state.JobTitle}`,
            IsRead: "false",
            ItemID: location.state.item.JobID
          })
        }
      })
    }
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    if (success) {
      message.success("Payment successful!!");
      Payment();
      handleApprovedReject("Approved", BidID);
      updateAppprovedReject("Approved");
      RejectRestbids();
    }
  }, [success]);


  console.log('milestone payment location', location.state.milestone);

  const Payment = async () => {
    try {
      const a = (parseInt(value) * 10) / 100
      const val = value - a
      const { data } = await axios.post(endPointUrl + "/user/applypayment", {
        Token,
        EmployerID: user.id,
        M_ID: location.state.milestone[Index].M_ID || 0,
        PM_ID: location.state.milestone[Index].PM_ID || 0,
        Payment_date: Orderdata.time,
        JobID: location.state.item.JobID,
        Bid_ID: BidID,
        Amount: value,
        EscrowedAmount: value,
        PaymentReference: Orderdata.ID,
      });
      message.success("confirmed")
      addprojects(data.PaymentID);
    } catch (error) {
      console.log('error', error);
      message.error("error while adding payment to logs");
    }
  };
  const addprojects = async (PaymentID) => {
    try {
      await axios.post(endPointUrl + "/addProjects", {
        Token,
        Awarded_By_ID: user.id,
        Bid_ID: BidID,
        Awarded_To_ID: location.state.item.UserID,
        JobID: location.state.item.JobID,
        JobTitle: location.state.item.Title,
        Project_Deadline: location.state.item.Duration,
        PaymentID: PaymentID,
        WalletUse: walletUse
      })
      message.success('Payment Escrowed')
    } catch (error) {
      console.log("error while adding project", error);
      message.error('error while adding Project')
    }
    navigate(-1);
  };


  console.log('location milestion', location.state.milestone);
  const updateLessHourMilestone = async (Bid_ID) => {
    try {
      const ind = 0;
      // const remainingHrs = parseInt(minHours) - location.state.milestone[0].timeSpend
      const value = (parseInt(BidAmount) * location.state.milestone[location.state.milestone.length - 1].timeSpend);
      let WalletAmount = (parseInt(BidAmount) * parseInt(minHours) - value)
      // let per = (amt * 10) / 100
      // amt = amt - per
      Swal.fire({
        title: "Project Completed, What do you want Continue?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Continue Project",
        denyButtonText: `Close Project`
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.post(endPointUrl + "/project/updatemilestone", {
            Token,
            PM_ID: location.state.milestone[location.state.milestone.length - 1].PM_ID,
            MilstoneStatus: "Approved",
            FreelancerPaymentStatus: "Released",
            Amount: value,
            FreelancerActualPaid: value - (value * 10) / 100
          })

          await axios.post(endPointUrl + "/project/createHourProjectMilestone", {
            Token,
            Bid_ID: Bid_ID,
            P_ID: location.state.milestone[0].P_ID,
            Description: location.state.milestone[0].Description,
            Amount: location.state.milestone[0].FreelancerTotalPaid,
            MilstoneStatus: "Pending",
            WalletAmount: WalletAmount,
          });

          navigate(-1);

        } else if (result.isDenied) {
          await axios.post(endPointUrl + "/project/updatemilestone", {
            Token,
            PM_ID: location.state.milestone[location.state.milestone.length - 1].PM_ID,
            MilstoneStatus: "Approved",
            FreelancerPaymentStatus: "Released",
            Amount: value,
            WalletAmount: WalletAmount,
            EscrowedAmount: value,
            FreelancerActualPaid: value - (value * 10) / 100
          })
          // if (ind == (location.state.milestone.length - 1)) {
          await axios.post(endPointUrl + "/UpdateProjects", {
            Token,
            P_ID: location.state.milestone[0].P_ID,
            Project_status: "Semi_Completed",
            RefundAmount: WalletAmount
          })
          // }
          socket.emit("notificationJobAssign", {
            Type: "Project",
            FromID: user.id,
            ToID: user.id,
            Message: `Project is completed for job ${location.state.JobTitle} `,
            IsRead: "false",
            ItemID: location.state.item.JobID
          })
          message.success(`Project Completed Successfully`)
          navigate(-1);
        }
      });
    } catch (error) {
      message.error("Unsuccessful in Approving milestone")
      console.log('errorsss', error)
    }
  }

  const updateEqualHourMilestone = (Bid_ID) => {
    try {
      Swal.fire({
        title: "Project Completed, What do you want Continue?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Continue Project",
        denyButtonText: `Close Project`
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.post(endPointUrl + "/project/updatemilestone", {
            Token,
            PM_ID: location.state.milestone[location.state.milestone.length - 1].PM_ID,
            MilstoneStatus: "Approved",
            FreelancerPaymentStatus: "Released",
            Amount: location.state.milestone[location.state.milestone.length - 1].Amount,
          })

          await axios.post(endPointUrl + "/project/createHourProjectMilestone", {
            Token,
            Bid_ID: Bid_ID,
            P_ID: location.state.milestone[0].P_ID,
            Description: location.state.milestone[0].Description,
            Amount: location.state.milestone[0].FreelancerTotalPaid,
            MilstoneStatus: "Pending",
            WalletAmount: 0,
          });

          navigate(-1);

        } else if (result.isDenied) {
          await axios.post(endPointUrl + "/project/updatemilestone", {
            Token,
            PM_ID: location.state.milestone[location.state.milestone.length - 1].PM_ID,
            MilstoneStatus: "Approved",
            FreelancerPaymentStatus: "Released",
            Amount: location.state.milestone[location.state.milestone.length - 1].Amount,
            WalletAmount: 0
          })
          // if (ind == (location.state.milestone.length - 1)) {
          await axios.post(endPointUrl + "/UpdateProjects", {
            Token,
            P_ID: location.state.milestone[0].P_ID,
            Project_status: "Semi_Completed",
            RefundAmount: 0
          })
          // }
          socket.emit("notificationJobAssign", {
            Type: "Project",
            FromID: user.id,
            ToID: user.id,
            Message: `Project is completed for job ${location.state.JobTitle} `,
            IsRead: "false",
            ItemID: location.state.item.JobID
          })
          message.success(`Project Completed Successfully`)
          navigate(-1);
        }
      });
    } catch (error) {
      message.error("Unsuccessful in Approving milestone")
      console.log('errorsss', error)
    }
  }

  const updateMilestone = async (PM_ID, Status, ind, P_ID, M_ID, Bid_ID) => {
    if ((ProjectType && parseInt(minHours) < parseInt(location.state.milestone[location.state.milestone.length - 1].timeSpend) && Status != "Rejected")) {
      setShow({ show1: !show.show1 })
      setIndex(ind);
      const remainingHrs = location.state.milestone[location.state.milestone.length - 1].timeSpend - parseInt(minHours);
      setValue(parseInt(BidAmount) * remainingHrs);
      setRemainPayment(true);
      setMID(M_ID)
      setBidID(Bid_ID)
    }
    else if ((ProjectType && parseInt(minHours) > parseInt(location.state.milestone[location.state.milestone.length - 1].timeSpend) && Status != "Rejected")) {
      setIndex(ind);
      setMID(M_ID);
      setBidID(Bid_ID);
      updateLessHourMilestone(Bid_ID);
    }
    else if ((ProjectType && parseInt(minHours) == parseInt(location.state.milestone[location.state.milestone.length - 1].timeSpend) && Status != "Rejected")) {
      setIndex(ind);
      setMID(M_ID);
      setBidID(Bid_ID);
      updateEqualHourMilestone(Bid_ID);
    }
    else {
      if (Status === "Approved") {
        try {
          await axios.post(endPointUrl + "/project/updatemilestone", {
            Token,
            PM_ID: PM_ID,
            MilstoneStatus: Status,
            FreelancerPaymentStatus: "Released"
          })

          if (ind == (location.state.milestone.length - 1)) {
            await axios.post(endPointUrl + "/UpdateProjects", {
              Token,
              P_ID: P_ID,
              Project_status: "Semi_Completed"
            })
          }

          socket.emit("notificationJobAssign", {
            Type: "Project",
            FromID: user.id,
            ToID: user.id,
            Message: `MileStone#${ind + 1} is completed for job ${location.state.JobTitle} `,
            IsRead: "false",
            ItemID: location.state.item.JobID
          })

          socket.emit("notificationJobAssign", {
            Type: "Project",
            FromID: user.id,
            ToID: location.state.item.UserID,
            Message: `${user.Name + " " + user.Last_Name} Approve Your Review Raised For Job Post ${location.state.JobTitle}`,
            IsRead: "false",
            ItemID: location.state.item.JobID
          })

          message.success(`MileStone ${Status} successfully`)
        } catch {
          message.error("Unsuccessful in Approving milestone")
        }
      } else {
        if (Reason.length === 0) {
          message.warning("Reason is Required");
          return;
        }
        try {
          await axios.post(endPointUrl + "/project/updatemilestone", {
            Token,
            PM_ID: PM_ID,
            MilstoneStatus: Status,
            timeSheetAttachment: '',
            timeSpend: '',
            descriptionWork: '',
            Reason: oldReason ? `${oldReason} ${todayDate} ${Reason},` : `${todayDate} ${Reason},`
          })

          socket.emit("notificationJobAssign", {
            Type: "Project",
            FromID: user.id,
            ToID: user.id,
            Message: `Milestone #${ind + 1} is rejected for job ${location.state.JobTitle} `,
            IsRead: "false",
            ItemID: location.state.item.JobID
          })

          socket.emit("notificationJobAssign", {
            Type: "Project",
            FromID: user.id,
            ToID: location.state.item.UserID,
            Message: `Milestone #${ind + 1} is rejected for job ${location.state.JobTitle} `,
            IsRead: "false",
            ItemID: location.state.item.JobID
          })

          message.success(`Milestone ${Status} successfully`)
        } catch (error) {
          console.log("error reject milestone", error);
          message.error("Unsuccessful in Rejecting milestone")
        }
      }
      navigate(-1);
    }
  };

  const updateAppprovedReject = async (status) => {
    try {
      await axios.post(endPointUrl + "/user/UpdateJobs", { Token, JobID: location.state.item.JobID, Status: status });
    } catch {
      message.error('error in updating status');
    }
  };

  const handleApprovedReject = async (status, Bid_ID) => {
    try {
      await axios.post(endPointUrl + "/user/updateBid", { Token, Bid_ID: Bid_ID, Bid_Status: status });
      // message.success(`Bid ${status} successfully`)
    } catch {
      message.error("Some occur on Approved/Rejection");
    }
  };

  const handleclick = (Amount, WalletAmount, Bid_ID, M_ID) => {
    if (WalletAmount && WalletAmount > 0) {
      setWalletUse(true);
      setValue(Amount - WalletAmount);
    }
    else {
      setValue(Amount);
    }
    setBidID(Bid_ID);
    setMID(M_ID);
  };

  const RejectRestbids = async () => {
    try {
      const result = await axios.post(endPointUrl + "/user/JobAssignedRejectBids", {
        BidID: BidID,
        Token
      })
    } catch (error) {
    }
  }

  useEffect(() => {
    getIndex();
    getTodayDate();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="home-howitwork" style={{ position: "absolute", top: "101px", left: "51px" }}>
        <Link to="/employer-job-profile">Home </Link>/{" "}
        <Link to="/milestone-payment">Milestone Payments </Link>
      </div>
      <PayPalScriptProvider options={{ "client-id": CLIENT_ID }}>
        <div className='Container'>
          <div className='header_Container'>
            <p>Complete your payment to approve this Project</p>
            {ProjectType ? <></> : <h2><span id='highlight'>MileStone</span> Payment</h2>}
          </div>
          <div className='cards_Container' style={{ flexDirection: "column", marginInline: "auto", maxWidth: "1080px" }}>
            {
              location.state.milestone.map((item, index) =>
                <div className='milestone_Card' key={index}>
                  <div className='mil_head'>
                    <h4>{ProjectType ? "" : `Milestone #${index + 1}`}</h4>
                    {item.EmployerPaymentStatus !== "Pending" ? item?.MilstoneStatus === "Up for Review" ?
                      <div className="biddingButton" style={{ margin: "0" }}>
                        <button className='mil_buton' style={{ background: "#07b273", fontSize: "14px", border: "2px solid #07b273", borderRadius: "5px" }}
                          onClick={() => updateMilestone(item.PM_ID, "Approved", index, item.P_ID, item.M_ID, item.Bid_ID)}
                        >
                          Approve</button>{" "}
                        <button className='mil_buton' style={{ background: "#bd5656fc", fontSize: "14px", border: "2px solid #bd5656fc", borderRadius: "5px" }}
                          // onClick={() => updateMilestone(item.PM_ID, "Rejected", index)}
                          onClick={() => {
                            setShowRejectionBox(!showRejectionBox)
                            setOldReason(item.Reason)
                          }}
                        >
                          Reject</button>
                      </div>


                      : <button className='mil_buton disabled'>{item?.FreelancerPaymentStatus === "Released" ? "Released" : "Escrowed"}</button>
                      : Index === index && location.state.milestone[index - 1]?.MilstoneStatus === "Approved" || Index === index && !location.state.milestone[index - 1]?.MilstoneStatus ?
                        <button className='mil_buton'
                          onClick={() => { setShow({ show1: !show.show1 }), handleclick(item.Amount, item.WalletAmount, item.Bid_ID, item.M_ID) }}
                        >Escrow Payment</button>
                        : null
                    }

                    {show.show1 && Index === index ?
                      // <PayPalButtons
                      //   fundingSource={FUNDING.PAYPAL}
                      //   style={{ layout: "vertical", color: "white", shape: "pill" }}
                      //   createOrder={(data, actions) => createOrder(data, actions)}
                      //   onApprove={onApprove}
                      // />
                      <PaymentModal open={show.show1} setOpen={setShow} createOrder={createOrder} onApprove={onApprove} value={item.Amount} />
                      : null}
                  </div>

                  {showRejectionBox ? <div style={{ boxShadow: '0px 0px 5px 2px gray', padding: '30px', boxSizing: 'border-box' }}>
                    <label>Reason For Rejection</label>
                    <textarea
                      placeholder="Enter Reason For Rejection..."
                      style={{ width: "95%" }}
                      onChange={(event) => {
                        if (event.target.value.split(" ").length > 50) {
                          message.warning("Reason not be greater than 50 words")
                        }
                        else
                          setReason(event.target.value)
                      }}
                    >
                    </textarea>
                    <div >
                      <button style={{ padding: '10px 20px', fontSize: '18px', borderRadius: '10px', outline: 'none', border: 'none', cursor: "pointer", color: 'white', background: '#07B273' }} onClick={() => updateMilestone(item.PM_ID, "Rejected", index, item.M_ID, item.Bid_ID)}>Submit</button>
                    </div>
                  </div> : <></>}
                  <hr />
                  <div className="mil_desc" style={{ display: "flex", flexDirection: "column" }}>
                    {location?.state?.milestone[0]?.P_ID && <div>
                      {ProjectType ?
                        <>
                          <div className='mil_detail'>
                            <h5>Total Amount:</h5>
                            <span>
                              {
                                location.state.milestone[index].timeSpend * BidAmount || 0
                              }
                            </span>
                          </div>
                          <div className='mil_detail'>
                            <h5>Escrowed Amount:</h5>
                            <span>{item?.EmployerPaymentStatus === "Released" ? item.EscrowedAmount : 0}</span>

                            {/* // <span>{item?.FreelancerPaymentStatus === "Released" ? */}
                            {/* location.state.milestone[index].timeSpend < minHours ? item.EscrowedAmount : */}
                            {/* location.state.milestone[index].timeSpend * BidAmount : item.EscrowedAmount || 0}</span> */}
                            {/* <span>{ProjectType && location.state.milestone[location.state.milestone.length - 1].timeSpend > minHours && location.state.milestone.length - 1 == index
                          ?
                          (location.state.milestone[location.state.milestone.length - 1].timeSpend - minHours) * BidAmount
                          :
                          ProjectType && location.state.milestone[location.state.milestone.length - 1].timeSpend < minHours && location.state.milestone.length - 1 == index ?
                            location.state.milestone[location.state.milestone.length - 1].timeSpend ? (location.state.milestone[location.state.milestone.length - 1].timeSpend) * BidAmount : item.Amount
                            :
                            item.Amount}
                          </span> */}
                            {/* <h5>Amount:</h5> <span>{ProjectType ? value ? value : item.Amount : item.Amount}</span> */}
                          </div>
                          <div className='mil_detail'>
                            <h5>Balance:</h5>
                            {
                              ProjectType && location.state.milestone.length - 1 == index
                                ?
                                (item.MilstoneStatus === "Up for Review" ? location.state.milestone[index].timeSpend * BidAmount - item.EscrowedAmount : 0) || 0
                                :
                                location.state.milestone[index].timeSpend < minHours ? location.state.milestone[index].timeSpend * BidAmount - item.EscrowedAmount : 0
                            }
                          </div>
                        </>
                        :
                        <div className='mil_detail'>
                          <h5>Amount:</h5>
                          <span>{item.Amount}</span>
                        </div>
                      }
                      <div className='mil_detail'>
                        <h5>Employer Payment Status:</h5> <span>{item.EmployerPaymentStatus}</span>
                      </div>
                      <div className='mil_detail'>
                        <h5>{ProjectType ? "Project Status" : "Milestone Status:"}</h5> <span>{item.MilstoneStatus}</span>
                      </div>
                      <div className='mil_detail'>
                        <h5>Freelancer Payment Status:</h5> <span>{item?.FreelancerPaymentStatus}</span>
                      </div>
                      <div className='mil_detail'>
                        {ProjectType ? <></> : <><h5>Due Date:</h5> <span>{moment(item.M_Due_Date).format("DD-MM-YYYY")}</span></>}
                      </div>
                    </div>}
                    {!location?.state?.milestone[0]?.P_ID &&
                      <div className='mil_detail'>
                        <h5>Amount:</h5><span>${item.Amount}</span>
                      </div>
                    }
                    <div className='mil_detail'>
                      <h5>Description:</h5><span style={{ wordBreak: "break-word" }}>{item.Description}</span>
                    </div>
                    {item.timeSheetAttachment ? <div className='mil_detail'>
                      <h5>Work Uploaded:</h5>
                      {/* <span>
                        <a href={`${endPointUrl}/images/${item.timeSheetAttachment}`} target='_blank'>
                          <img style={{ maxWidth: '300px', maxHeight: '300px' }} src={`${endPointUrl}/images/${item.timeSheetAttachment}`}></img>
                        </a>
                      </span> */}
                      {item.timeSheetAttachment?.substring(item.timeSheetAttachment.lastIndexOf(".") + 1) === "pdf" || item.timeSheetAttachment?.substring(item.timeSheetAttachment.lastIndexOf(".") + 1) === "docx" ?
                        <a target='_blank' href={`${endPointUrl}/images/${item.timeSheetAttachment}`}>
                          <div>
                            Click to View Document
                          </div>
                        </a>
                        :
                        <a target='_blank' href={`${endPointUrl}/images/${item.timeSheetAttachment}`}>
                          <img
                            className="image-thumbnail"
                            src={`${endPointUrl}/images/${item.timeSheetAttachment}`}
                            alt="pic"
                          // onClick={() => handleImageDownload(`${endPointUrl}${fileURLs}`, filename)}
                          // download={true}
                          />
                        </a>}
                    </div> : <></>}
                  </div>
                </div>
              )}
          </div>
        </div>
      </PayPalScriptProvider>
    </>
  )
}

export default MilestonePayment