import React, { useEffect, useContext } from "react";
import { MyContext } from "./Mycontext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CategoryFooter from "./components/CategoryFooter";
import Header from "./components/Header";
import WhyDigilanxer from "./components/WhyDigilanxer.js";
import HowItWorks from "./components/HowItWorks.js";
import HireFreelancers from "./components/HireFreelancers.js";
import IndexV1 from "./pages/IndexV1";
import "./global.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import UserProfile from "./components/UserProfile";
import EmployerProfile from "./components/EmployerProfile";
import Projectlisted from "./components/Projectlisted";
import Login from "./components/login_signup/Login";
import Signup from "./components/login_signup/Signup";
import Logeduser from "./components/Logeduser";
import Employerprofile from "./components/user-profile/employerprofile";
import FreelancerJobprofile from "./components/user-profile/FreelancerJobprofile";
import EmployerJobProfile from "./components/user-profile/EmployerJobProfile";
import Sidebar from "./components/user-profile/Sidebar";
import Jobform from "./components/Jobs/Jobform";
import Package from "./components/Jobs/Package";
import Thanku from "./components/Jobs/Thanku";
import MilestonePayment from "./components/Jobs/milestonePayment";
import SkillTest from "./components/user-profile/SkillTest";
import SKillQA from "./components/user-profile/SKillQA";
import InvoiceGenerator from "./components/user-profile/invoince/InvoiceGenerator";
import DetailView from "./components/Jobs/DetailView";
import ReviewContainer from "./components/reviewContainer";
import PrivacyPolicy from "./components/footer-terms/PrivacyPolicy";
import TermsCondition from "./components/footer-terms/TermsCondition";
import EscrowPaymentSustem from "./components/footer-terms/EscrowPaymentSustem";
import CopyrightPolicy from "./components/footer-terms/CopyrightPolicy";
import Contact from "./components/footer-terms/Contact";
import Sitemap from "./components/Sitemap";
import GuideOne from "./components/Digilanxer_Guide/GuideOne";
import UserReviews from "./components/UserReviews.js";
import AllNotification from "./components/AllNotification.js";
import ChatPage from "./components/ChatPage.js";
import DetailviewJob from "./components/user-profile/DetailviewJobfreelancer.js";
import DetailviewJobEmployer from "./components/user-profile/DetailviewJobEmployer.js";
import EarningDetail from "./components/EarningDetail.js";
import Product from "./components/Product.js";
import ProductInfo from "./components/ProductInfo.js";
import PartnerWithUs from "./components/PartnerWithUs.js";
import BecomePartner from "./components/BecomePartner.js"
import GenerateCertificate from "./components/GenerateCertificate.js";
import Certificate from "./components/Certificate.js";
import Skilltestinvoicegenerator from "./components/user-profile/invoince/SkillTestInvoiceGenerator.js";
import LinkedInCallback from "./components/login_signup/linkedin.js";
import axios from "axios";
// import DetailviewJob from "./components/user-profile/DetailviewJob.js";

function App() {
  const { user, setuser, socket, endPointUrl,setChatUsers, setOnlineUser, setUnreadUsers, Token } = useContext(MyContext);
  const navigate = useNavigate();

  useEffect(() => {
    socket?.emit('addUser', user?.id)
    socket?.on("getOnlineUsers", data => {
      console.log("USER", data);
      setOnlineUser(data)
    })

    return () => {
      socket?.off("getOnlineUsers");
      socket?.off("addUser");
    }
  }, [socket]);

  const get_user = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/getUserById", {
        Token,
        id: user.id,
      });
      if (data?.status) {
        setuser(data?.data);
      }
    } catch (e) {
      console.log('error while get user', e);
    }
  };

  console.log('user', user);  


  useEffect(() => {
    get_user()
  }, [])

  useEffect(() => {
    socket?.on('recieve-messageCount', data => {
      const { sender, message } = data;
      setUnreadUsers(prevUnreadUsers => {
        // Ensure prevUnreadUsers is initialized properly as a Set
        const updatedUnreadUsers = prevUnreadUsers instanceof Set ? new Set(prevUnreadUsers) : new Set();

        if (!updatedUnreadUsers.has(sender)) {
          updatedUnreadUsers.add(sender);
        }

        return updatedUnreadUsers;
      });

      setChatUsers(prevUsers => {
        const updatedUsers = prevUsers.map(user => {
          if (user.user.id === sender) {
            return {
              ...user,
              lastChat: {
                ...user.lastChat,
                message: message,
                IsRead: false
              }
            };
          }
          return user;
        });

        // Find the index of the user with the sender ID
        const senderIndex = updatedUsers.findIndex(user => user.user.id === sender);
        if (senderIndex !== -1) {
          // Remove the user from its current position and insert it at the beginning
          const senderUser = updatedUsers.splice(senderIndex, 1)[0];
          updatedUsers.unshift(senderUser);
        }

        return updatedUsers;
      });
    });
  }, [socket]);


  return (
    <>
      {/* <IndexV1/> */}
      <GoogleOAuthProvider clientId="589747976666-illkm8moqfngragqqcp54h76njmr4hu6.apps.googleusercontent.com">
        <Header />
        <Routes>
          <Route path="/" element={<IndexV1 />} />
          <Route path="/why-digilanxer" element={<WhyDigilanxer />} />
          <Route path="/reviews" element={<ReviewContainer />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/freelancers" element={<HireFreelancers />} />
          <Route path="/freelancer/:cname" element={<HireFreelancers />} />
          <Route path="/user-profile/:nameid" element={<UserProfile />} />
          <Route path="/user-profile/reviews/:nameid" element={user ? <UserReviews /> : <Navigate to="/login" />} />
          <Route path="/employer-profile/:id" element={user ? <EmployerProfile /> : <Navigate to="/login" />} />
          <Route path="/jobs" element={<Projectlisted />} />
          <Route path="/jobs/:jobname" element={<Projectlisted />} />
          <Route path="/job-detail-view" element={<DetailView />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login-user-profile" element={user ? <Logeduser /> : <Navigate to="/login" />} />
          <Route path="/employer-profile" element={user ? <Employerprofile /> : <Navigate to="/login" />} />
          <Route path="/all-notification" element={user ? <AllNotification /> : <Navigate to="/login" />} />
          <Route path="/chat-user/:id" element={user ? <ChatPage /> : <Navigate to="/login" />} />
          <Route path="/chat-user" element={user ? <ChatPage /> : <Navigate to="/login" />} />
          {/* <Route path="/freelancer-job-profile" element={<FreelancerJobprofile />} />
          <Route path="/employer-job-profile" element={<EmployerJobProfile />} /> */}
          <Route path="/freelancer-job-profile" element={user ? <FreelancerJobprofile /> : <Navigate to="/login" />} />
          <Route path="/employer-job-profile" element={user ? <EmployerJobProfile /> : <Navigate to="/login" />} />
          <Route path="/detail-view-job/:id" element={user ? <DetailviewJob /> : <Navigate to="/login" />} />
          <Route path="/detail-view-jobE/:id" element={user ? <DetailviewJobEmployer /> : <Navigate to="/login" />} />
          <Route path="/job-creation" element={user ? <Jobform /> : <Navigate to="/login" />} />
          <Route path="/packages" element={user ? <Package /> : <Navigate to="/login" />} />
          <Route path="/thank-you" element={user ? <Thanku /> : <Navigate to="/login" />} />
          <Route path="/milestone-payment" element={user ? <MilestonePayment /> : <Navigate to="/login" />} />
          <Route path="/certification" element={<Certificate />} />
          <Route path="/skill-test" element={user ? <SkillTest /> : <Navigate to="/login" />} />
          <Route path="/skill-qa" element={user ? <SKillQA /> : <Navigate to="/login" />} />
          <Route path="/invoice-generator" element={user ? <InvoiceGenerator /> : <Navigate to="/login" />} />
          <Route path="/Test-Invoice-Generator" element={user ? <Skilltestinvoicegenerator /> : <Navigate to="/login" />} />
          <Route path="/digi-guide/:GID" element={<GuideOne />} />
          {/* <Route path="/Invoice-Generator" element={<InvoiceGenerator />} />
          <Route path="/Invoice-Generator" element={<InvoiceGenerator />} /> */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/fees-and-charges" element={<EscrowPaymentSustem /> }/>
          <Route path="/copy-right-policy" element={<CopyrightPolicy />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/sitemap" element={user ? <Sitemap /> : <Navigate to="/login" />} />
          <Route path="/earning-detail/:id" element={user ? <EarningDetail /> : <Navigate to="/login" />} />
          <Route path="/product" element={<Product />} />
          <Route path="/productInfo" element={<ProductInfo />} />
          <Route path="/partner-with-us" element={<PartnerWithUs />} />
          <Route path="/become-partner" element={<BecomePartner />} />
          <Route path="/generate-certificate" element={user ? <GenerateCertificate /> : <Navigate to="/login" />} />
          <Route path="/linkedin-callback" element={<LinkedInCallback />} />
        </Routes>
        <CategoryFooter />
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
