import { Modal, Typography, Box, FormControlLabel, Checkbox, Button } from '@mui/material'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { useNavigate } from 'react-router-dom'

const CertificateDetailModel = ({ takenTest }) => {
    const navigate = useNavigate();
    return (

        <div>
            <Box sx={{}}>
                <hr />
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {
                        takenTest.map((item) => {
                            return (<div style={{ width: "100%" }}>
                                <div style={{ display: 'flex', alignItems: 'center', width: "100%" }}>
                                    <div style={{ width: "33%" }}>{item?.userexam?.SKT_Title}</div>
                                    <div style={{ width: "33%", textAlign: 'center', color: item.Result === "Pass" ? "green" : "red" }}>{item?.Result}</div>
                                    <div style={{ width: "33%", textAlign: 'right' }}>
                                        {item.Result === "Pass" ?
                                            <div
                                                onClick={() => {
                                                    navigate("/generate-certificate", { state: { SKT_ID: item.SKT_ID, expire_date: item?.userexam?.updatedAt } })
                                                }}
                                                // className='mil_buton'
                                                style={{ width: "auto", padding: "4px 21px", textAlign: "center" }}
                                            >
                                                <DownloadForOfflineIcon style={{ color: "#07b273", fontSize: "40px", cursor: 'pointer' }} />
                                            </div > :
                                            <>
                                                <button
                                                    onClick={
                                                        () => navigate("/skill-qa", { state: { SKT_ID: item.SKT_ID, duration: item?.userexam?.Duration} }
                                                        )}
                                                    style={{ width: "auto", padding: "5px 6px", fontSize: '12px' }}
                                                    className='mil_buton'
                                                >
                                                    Reattempt
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>

                                <hr />

                            </div>)
                        })
                    }
                </Typography>
            </Box>
        </div>
    )

}

export default CertificateDetailModel;