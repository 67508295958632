import { useEffect, useState, useContext } from "react";
import img1 from "../images/point-1.png";
import { MyContext } from "../Mycontext";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { message } from "antd";


export default function ProductInfo() {
    const location = useLocation();
    const navigate = useNavigate();
    const productinfo = location?.state?.item;
    const [productInfo, setProductInfo] = useState(productinfo)
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [showForm, setShowForm] = useState(false);
    const [name, setName] = useState(user?.Name || "");
    const [emailID, setEmailID] = useState(user?.Email || "");
    const [description, setDesciption] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [error, setError] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function handleError(label, error) {
        setError((prev) => ({ ...prev, [label]: error }))

    }

    function validate() {
        let errors = false;
        if (name.length === 0) {
            errors = true;
            handleError('Name', 'Name is Required')
        }
        if (emailID.length === 0) {
            errors = true;
            handleError('emailID', 'EmailID is Required')
        }
        if (description.length === 0) {
            errors = true;
            handleError('description', 'Description is Required');
        }
        if (contactNumber.length < 12) {
            errors = true;
            handleError('contactNumber', 'Contact Number must be 10 character');
        }

        return errors;
    }

    const handleSendRequest = async (event) => {
        event.preventDefault()
        const error = validate()
        if (error === false) {
            try {
                const { data } = await axios.post(endPointUrl + "/addProductInterested", {
                    Name: name,
                    Email: emailID,
                    Description: description,
                    Product_ID: productInfo?.Product_ID,
                    ProductName: productInfo?.ProductName,
                    Phone: contactNumber,
                })

                if (data?.status) {
                    message.success("Interest Request Submitted Successfully")
                    navigate("/product");
                }
            } catch (e) {
                message.warning("Somthing Went Wrong!")
            }
        }
    }

    return (
        <div className="HowItWorks">

            <div className="Apart-points" style={{ justifyContent: 'center' }}>
                <div className="apart-img" style={{ marginTop: '2%' }}>
                    <img src={`${endPointUrl}/images/${productInfo?.Image}`} alt="" />
                </div>

                <div className="Apart-containet" style={{ marginTop: '2%' }}>
                    <h1>{productInfo?.ProductName}</h1>
                    <span className="aprt-containet-head">
                        {productInfo?.PluginInfo}
                    </span>
                    <span className="aprt-containet-value" style={{ marginTop: '2%' }}>
                        {productInfo?.Description}
                    </span>

                    <span style={{ marginTop: '3%' }}>
                        <a href="#interestForm">
                            <button className="Product_Info_Interest" style={{ width: '40%' }} onClick={() => setShowForm(!showForm)}>Interested</button>
                        </a>
                    </span>
                </div>
            </div>


            {showForm ? <div className="Apart-points" id="interestForm" style={{ flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ fontSize: '22px', fontWeight: 'bold' }}>Fill Form</div>
                <div style={{ fontSize: '14px' }}>(If you are interested in product)</div>

                <div style={{ marginTop: '2%', width: '50%' }} className="Product_Info">
                    <form>
                        <div>
                            <label htmlFor="name">Name<span className="asterisk">*</span></label>
                            <input id="name" style={{ width: '100%' }} type="text" placeholder="Enter your name"
                                value={name}
                                onChange={(event) => {
                                    setName(event.target.value);
                                }}
                                onFocus={() => {
                                    setError({ ...error, Name: '' })
                                }}
                            />
                            <small style={{ color: "#ec1c24" }}>{error.Name}</small>
                        </div>

                        <div>
                            <label htmlFor="emailID">Email ID<span className="asterisk">*</span></label>
                            <input id="emailID" style={{ width: '100%' }} type="email" placeholder="Enter your email"
                                value={emailID}
                                onChange={(event) => {
                                    setEmailID(event.target.value);
                                }}
                                onFocus={() => {
                                    setError({ ...error, emailID: '' })
                                }}
                            />
                            <small style={{ color: "#ec1c24" }}>{error.emailID}</small>

                        </div>

                        <div>
                            <label htmlFor="description">Description<span className="asterisk">*</span></label>
                            <textarea id="description" style={{ width: '100%' }} placeholder="Enter description"
                                onChange={(event) => {
                                    if (event.target.value.length <= 200) {
                                        setDesciption(event.target.value);
                                        setError({ ...error, description: '' })
                                    }
                                    else {
                                        setError({ ...error, description: "Description Must be Smaller than 200 character" })
                                    }
                                }}
                                onFocus={() => {
                                    setError({ ...error, description: '' })
                                }}
                            ></textarea>
                            <small style={{ color: "#ec1c24" }}>{error.description}</small>
                        </div>

                        <div>
                            <PhoneInput
                                buttonStyle={{ marginTop: '0%' }}
                                country={'in'}
                                value={contactNumber}
                                onChange={(phone) => setContactNumber(phone)}
                                onFocus={() => setError({ ...error, contactNumber: "" })}
                            />
                            <small style={{ color: "#ec1c24" }}>{error.contactNumber}</small>
                        </div>

                        <div>
                            <button onClick={handleSendRequest}>Send Request</button>
                        </div>
                    </form>
                </div>

            </div>
                : <></>}
        </div >
    )
}