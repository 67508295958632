import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { MyContext } from "../../../Mycontext"
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import moment from 'moment';
// import { useHistory } from "react-router-dom";
// import PaginationSection from "./PaginationSection";
// import ReactPaginate from 'react-paginate';
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form
} from "react-bootstrap";
import { Link } from "react-router-dom";
import SkillInvoice from "./SkillInvoice";


function Invoices() {
  const { endPointUrl, Token, user } = useContext(MyContext);
  const [allDetails, setallDetails] = useState([]);
  const [sortOption, setSortOption] = useState("Latest");

  function generateInvoiceNumber(paymentDate, paymentId, Subscription_Date, Sub_ID) {
    const date = new Date(paymentDate || Subscription_Date);
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to month because it's zero-based
    const invoiceCountStr = '001'; // You can start with '001' and increment it for each invoice
    const invoiceNumber = `INV${year}${month}${paymentId || Sub_ID?.toString().padStart(3, '0')}`;
    return invoiceNumber;
  }


  const getPayment = async () => {
    if (user.Type == "Employer") {
      try {
        const { data } = await axios.post(endPointUrl + "/user/getPaymentByEmployerID", {
          Token,
          EmployerID: user.id
        })
        setallDetails(data.data);

      } catch (err) {
        console.log(err);
      }
    } else {
      try {

        const { data } = await axios.post(endPointUrl + "/user/getsubscriptionbyUserID", {
          Token,
          UserID: user.id
        })
        console.log("DAG", data)
        setallDetails(data.data);

      } catch (err) {
        console.log(err);
      }
    }
  };
  // const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [], [allDetails]);
  const data = React.useMemo(() => Array.isArray(allDetails) ? allDetails : [allDetails], [allDetails]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Invoice Number",
        accessor: "", // Access the payment date to generate the invoice number
        Cell: ({ row }) => (
          <span > {generateInvoiceNumber(row.original.Payment_date, row.original.Payment_ID, row.original.Subscription_Date, row.original.Sub_ID)
          }</span >
        ),
      },
      {

        Header: user.Type == "Freelancer" ? "Bids" : "Project",
        accessor: user.Type == "Freelancer" ? "NumOfbids" : "Jobdetails.Title",
      },
      {
        Header: "Amount",
        accessor: "Amount",
      },
      {
        Header: "Payment Date",
        accessor: user.Type == "Freelancer" ? "Subscription_Date" : "Payment_date",
        Cell: ({ value }) => (
          <span>{moment(value).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        Header: "Payment Reference",
        accessor: user.Type == "Freelancer" ? "Payment_Reference" : "PaymentReference",
      },
      {
        Header: "Action",
        accessor: user.Type == "Freelancer" ? "Sub_ID" : "Payment_ID",
        Cell: ({ row }) => (
          <div>
            {/* "Update" icon */}
            <span
              onClick={() => {
                if (user.Type == "Freelancer") {
                  handleclick(row.original.Sub_ID,
                    row.original.Subscription_Date)

                } else {
                  handleclick(row.original.Payment_ID,
                    row.original.Payment_date)
                }
              }
              }
              style={{ cursor: "pointer", marginRight: "10px" }}
            >
              <Button style={{ fill: "#07b274" }}>View</Button>
            </span>
          </div >
        ),
      },
    ],
    []
  );



  useEffect(() => {
    getPayment();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
      sortBy: [{ id: "Payment_date", desc: sortOption === "Oldest" }],
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex, pageSize } = state;

  // const handleSortChange = (event) => {
  //   setSortOption(event.target.value);
  // };


  const handleclick = (Id, paymentDate) => {
    const invoiceNumber = generateInvoiceNumber(paymentDate, Id);
    // const url = `/Invoice-Generator/${Id}/${invoiceNumber}`;
    const url = `/invoice-generator/?invoiceNumber=${invoiceNumber}&Id=${Id}`;
    window.open(url, '_blank'); // Open the URL in a new tab
  };
  return (
    <>

      <Container fluid >
        <Row>
          <Col lg="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">
                  <Form style={{ display: "flex", justifyContent: "space-between" }} >
                    <Col style={{ width: "100%" }} >
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        style={{
                          width: "100%",
                          padding: "8px",
                          border: " 1px solid #efefef",
                          borderRadius: "5px"
                        }}
                        aria-label="Search"
                        value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
                      />
                    </Col>
                  </Form>
                </Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped " {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr  {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (<td  {...cell.getCellProps()}> {cell.render("Cell")}</td>)
                          })}
                        </tr>
                      );
                    }

                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {user?.Type === "Freelancer" && < Row style={{ marginTop: '100px' }}>
          <SkillInvoice />
        </Row>}
      </Container >



    </>
  );
}

export default Invoices;