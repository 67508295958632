import React, { useState, useContext } from 'react';
import { MyContext } from '../../Mycontext';
import axios from 'axios';
import { message } from 'antd';
import { Link } from 'react-router-dom'



export default function Contact() {
    const { endPointUrl, Token } = useContext(MyContext);
    const [form, setForm] = useState({
        Name: "",
        Email: "",
        Phone: "",
        Message: ""
    });
    const [errors, setErrors] = useState({});

    // Function to send the email
    const sendMail = async () => {
        try {
            await axios.post(endPointUrl + '/user/contactMail', {
                Token,
                ...form
            });
            message.success("Message sent");

            // Clear the form fields after successful submission
            setForm({
                Name: "",
                Email: "",
                Phone: "",
                Message: ""
            });
        } catch {
            message.error("Error sending message");
        }
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            sendMail();
        } else {
            setErrors(validationErrors);
        }
    };

    // Function to validate the form
    const validateForm = () => {
        const validationErrors = {};
        if (!form.Name) {
            validationErrors.Name = "Name is required";
        }
        if (!form.Email) {
            validationErrors.Email = "Email is required";
        }
        if (!form.Phone) {
            validationErrors.Phone = "Phone is required";
        }
        if (!form.Message) {
            validationErrors.Message = "Message is required";
        }
        // You can add more specific validation rules as needed

        return validationErrors;
    };

    return (
        <div className='HowItWorks contact'>
            <h2>Send us a Message</h2>
            <hr />
            <div className='contact-container'>
                <div className='contact-mainpanel'>
                    <form
                        className='contact-form JobPostForm'
                        onSubmit={handleSubmit}
                    >
                        <div className='contact-form-upper'>
                            <div className='contact-form-detail'>
                                <label htmlFor="Name">Name<span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    name="Name"
                                    value={form.Name}
                                    onChange={(e) => setForm({ ...form, Name: e.target.value })}
                                />
                                {errors.Name && <small style={{ color: "red" }}>{errors.Name}</small>}

                                <label htmlFor="Email">Email<span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    name="Email"
                                    value={form.Email}
                                    onChange={(e) => setForm({ ...form, Email: e.target.value })}
                                />
                                {errors.Email && <small style={{ color: "red" }}>{errors.Email}</small>}

                                <label htmlFor="Phone">Phone<span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    name="Phone"
                                    value={form.Phone}
                                    onChange={(e) => setForm({ ...form, Phone: e.target.value })}
                                />
                                {errors.Phone && <small style={{ color: "red" }}>{errors.Phone}</small>}
                            </div>

                            <hr />
                            <label htmlFor="Message">Message<span style={{ color: "red" }}>*</span></label>
                            <textarea
                                name="Message"
                                cols="30"
                                rows="10"
                                value={form.Message}
                                onChange={(e) => setForm({ ...form, Message: e.target.value })}
                            >
                            </textarea>
                            {errors.Message && <small style={{ color: "red" }}>{errors.Message}</small>}
                            <hr />
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <input
                                    type="submit"
                                    value="Send Message"
                                    className="buton"
                                    style={{ marginBlockStart: "20px" }}
                                />
                            </div>
                        </div>
                    </form>
                </div>

                <hr />
                <div className='contact-sidepanel'>
                    <div style={{ maxWidth: "100%", overflow: "hidden", color: "red", width: "300px", height: "300px" }}>
                        <div id="embedded-map-display" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                            <iframe style={{ height: "100%", width: "100%", border: "0" }} frameBorder="0" src="https://www.google.com/maps/embed/v1/place?q=5900+Balcones+Drive+suite+100,+Austin,+Texas+78731,+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
                            </iframe>
                        </div>
                    </div>
                    <p>5900 Balcones Drive STE 100
                        Austin
                        Texas-78731</p>
                    <p>Phone: 1-707-225-8072</p>
                    <p>Monday - Friday 8 AM - 10 PM EST</p>
                </div>
            </div>
        </div>
    );
}

