import React, { useContext, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useLocation } from 'react-router-dom';
import { MyContext } from '../Mycontext';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import logo from "../images/Logo.png";

const GenerateCertificate = () => {
    const location = useLocation();
    const { Token, user, endPointUrl } = useContext(MyContext);
    const [skill, setSkill] = useState('');

    const SKT_ID = location?.state?.SKT_ID;

    const getSkilltest = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/getskilltestByID", { Token, SKT_ID });
            setSkill(data.data);
        } catch (error) {
            console.log('error', error);
        }
    };

    const updateUser = async () => {
        try {
            await axios.post(endPointUrl + "/updateUserProfileProgress", { Token, id: user?.id, digilanxerCertified: true });
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getSkilltest();
        updateUser();
    }, []);

    const downloadPdf = () => {
        const certificate = document.getElementById('certificate');
        html2canvas(certificate, { scale: 3 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('landscape', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('certificate.pdf');
        });
    };

    const getCurrentDateFormatted = () => {
        if (location?.state?.expire_date) {
            const date = new Date(location?.state?.expire_date);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        }
        const date = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };


    const getExpireDateFormatted = () => {
        if (location?.state?.expire_date) {
            const today = new Date(location?.state?.expire_date);
            const expireDate = new Date(today);
            expireDate.setFullYear(today.getFullYear() + 1);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return expireDate.toLocaleDateString('en-US', options);
        }
        const today = new Date();
        const expireDate = new Date(today);
        expireDate.setFullYear(today.getFullYear() + 1);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return expireDate.toLocaleDateString('en-US', options);
    };

    return (
        <>
            <Box sx={{ width: "60%", margin: 'auto', padding: "50px 0px" }}
                id="certificate"
            >
                <Box
                    sx={{
                        width: '90%',
                        margin: 'auto',
                        border: '2px solid #07b273',
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        boxSizing: 'border-box',
                        display: 'flex'
                    }}
                >

                    <Box sx={{ width: "25%", background: "#07B273", padding: "0px" }}>
                        <Typography style={{ textAlign: 'center', marginTop: '10%' }}>
                            <WorkspacePremiumIcon style={{ fontSize: '100px', color: '#ffff' }} />
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            boxSizing: 'border-box',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            position: 'relative',
                            padding: '25px 32px',
                            width: "75%",
                            mt: 1
                        }}
                    >
                        {/* <Typography style={{ marginLeft: 'auto', position: 'absolute', right: '10px', top: '5px' }}>
                            <WorkspacePremiumIcon style={{ fontSize: '60px', color: '#07B273' }} />
                        </Typography> */}
                        <Typography variant="h4" gutterBottom>
                            <img src={logo} alt="digilanxer logo" style={{ width: "30%" }} />
                        </Typography>

                        <Typography variant="h5" gutterBottom sx={{ mt: 1 }}>
                            Certificate of Completion
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            This certificate is awarded to
                        </Typography>
                        <Typography variant="h5" gutterBottom style={{ color: '#07b273' }}>
                            {user.Name} {user.Last_Name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            in recognition of successful completion of the course
                        </Typography>
                        <Typography variant="h5" gutterBottom style={{ color: '#07b273' }}>
                            {/* <div style={{ fontWeight: 'bold', fontSize: '26px', color: '#07b273' }}>Mern stack</div> */}
                            {skill?.SKT_Title}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            on {getCurrentDateFormatted()}
                        </Typography>
                        <Typography variant="body2" gutterBottom style={{ marginTop: '3%' }}>
                            <div style={{ fontSize: '10px' }}>This Certificate will expire on</div>
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            <div style={{ fontSize: '10px' }}>{getExpireDateFormatted()}</div>
                        </Typography>

                        {/* <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'left',
                                width: '80%',
                                marginTop: '32px',
                            }}
                        >
                            <Box>
                                <Typography variant="body2" gutterBottom>
                                    <strong>Rajat Agarwal</strong>
                                    <br />
                                    Instructor
                                </Typography>
                            </Box>
                        </Box> */}
                    </Box>
                </Box>
            </Box>
            <Box style={{ textAlign: 'center', margin: '3%' }}>
                <button className='invite' onClick={downloadPdf}>Download Certificate</button>
            </Box>
        </>
    );
};

export default GenerateCertificate;
