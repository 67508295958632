import { Modal, Box, Typography, IconButton } from "@mui/material";
import { PayPalButtons } from "@paypal/react-paypal-js";
import Stripe from "../../images/stripe.png";
import Razorpay from "../../images/Razorpay.png";
import Amazonpay from "../../images/amazon-pay.png";

const PaymentModal = ({ open, setOpen, createOrder, onApprove, value }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        borderRadius: '15px'
    };

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <Typography variant="h5" component="h2" sx={{ fontSize: "20px", color: "#fff", fontWeight: 'bold', background: '#07b273', padding: "15px", borderRadius: '15px 15px 0px 0px' }}>
                    Select a payment option
                </Typography>
                <Typography sx={{ mt: 2, display:'flex', justifyContent:'center' }}>
                    <Box sx={{ width: "80%", padding: "40px", boxSizing: 'border-box', textAlign: 'center' }}>
                        <Typography>
                            <PayPalButtons
                                // fundingSource={paypal?.FUNDING.PAYPAL}
                                style={{ layout: "horizontal", color: "silver", tagline: false, label: "pay" }}
                                createOrder={(data, actions) => createOrder(value, data, actions)}
                                onApprove={onApprove}
                            />
                        </Typography>

                        <Typography sx={{ mt: 4 }}>
                            <img src={Amazonpay} style={{ width: '40%' }} />
                        </Typography>


                        <Typography sx={{ mt: 4 }}>
                            <img src={Stripe} style={{ width: '30%' }} />
                        </Typography>


                        <Typography sx={{ mt: 4 }}>
                            <img src={Razorpay} style={{ width: '40%' }} />
                        </Typography>
                    </Box>
                </Typography>
            </Box >
        </Modal >
    )
}

export default PaymentModal;