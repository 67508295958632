import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { MyContext } from "../../Mycontext";
import editbutton from '../../images/edit.png'
import deletebutton from '../../images/delet2.png'
import { message } from 'antd';
import { Country } from "country-state-city";
import Select from "react-select";

function EditWorkExp({ data, getExp, CloseModel }) {
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [exp, setExp] = useState([]);
    const [newForm, setNewForm] = useState({
        U_Ex_ID: data.U_Ex_ID || "",
        Company: data.Company || "",
        Location: data.Location || "",
        Designation: data.Designation || "",
        From: data.From.slice(0, 10) || "",
        to: data.to.slice(0, 10) || "",
        Description: data.Description || "",
    });
    const [errorForm, setErrorForm] = useState({
        Company: "",
        Location: "",
        Designation: "",
        From: "",
        to: "",
        Description: "",
    });
    const [expireDatetoggle, setExporeDatetoggle] = useState(newForm?.to === "Present" ? true : false)

    const handleEditsubModel = (e, value) => {
        e.preventDefault();

        const newFormErrors = {
            Company: "",
            Location: "",
            Designation: "",
            From: "",
            to: "",
            Description: "",
        };
        let showError = false;

        if (newForm.Company === null || newForm.Company === "") {
            showError = true;
            newFormErrors.Company = 'Please fill out this field!';
        }
        else if (newForm?.Company && newForm?.Company.length >= 50) {
            showError = true;
            newFormErrors.Company = 'Company Name less than 50 words';
        }
        else {
            newFormErrors.Company = '';
        }

        if (newForm.Location === null || newForm.Location === "") {
            showError = true;
            newFormErrors.Location = 'Please fill out this field!';
        }
        else {
            newFormErrors.Location = "";
        }
        if (newForm.Designation === null || newForm.Designation === "") {
            showError = true;
            newFormErrors.Designation = 'Please fill out this field!';
        } else {
            newFormErrors.Designation = '';
        }
        if (newForm.From === null || newForm.From === "") {
            showError = true;
            newFormErrors.From = 'Please fill out this field!';
        } else {
            newFormErrors.From = '';
        }
        if (newForm.to === null || newForm.to === "") {
            showError = true;
            newFormErrors.to = 'Please fill out this field!';
        } else {
            newFormErrors.to = '';
        }
        if (newForm.to <= newForm.From) {
            showError = true;
            newFormErrors.to = 'To date greater that from date';
        } else {
            newFormErrors.to = '';
        }
        if (newForm.to <= newForm.From) {
            showError = true;
            newFormErrors.to = 'To date greater that from date';
          } else {
            newFormErrors.to = '';
          }
          if (!newForm.Description || newForm.Description.trim() === "") {
            showError = true;
            newFormErrors.Description = 'Please fill out this field!';
          } else if (newForm.Description.length < 100) {
            showError = true;
            newFormErrors.Description = 'Minimum 100 characters required!';
          } else if (newForm.Description.length > 300) {
            showError = true;
            newFormErrors.Description = 'Maximum 300 characters allowed!';
          } else {
            newFormErrors.Description = '';
          }
        // if (newForm.Description === null || newForm.Description === "") {
        //     showError = true;
        //     newFormErrors.Description = 'Please fill out this field!';
        // } else if (newForm.Description.length < 100) {
        //     newFormErrors.Description = 'Minimum 100 character required!';
        // }
        // else if (newForm.Description.length > 300) {
        //     newFormErrors.Description = 'Maximum 300 character allowed!';
        // }
        // else {
        //     newFormErrors.Description = '';
        // }
        setErrorForm(newFormErrors);

        if (!showError) {
            updateExp();
        }

    };

    const updateExp = async () => {
        try {
            await axios.post(endPointUrl + "/user/updateUserExp", {
                ...newForm,
                U_Ex_ID: data.U_Ex_ID,
                Token,
            });
            CloseModel();
            getExp();
            message.success("Update Successful");
        } catch {
            message.error("Something went Wrong ");
        }
    };


    const getExps = async () => {
        const { data } = await axios.post(endPointUrl + "/user/getuserExp", {
            UserID: user.id,
            Token,
        });
        setExp(data.data);
    };
    useEffect(() => {
        getExps();
    }, []);


    const checkValidDate = (date) => {
        let flag = false;
        const enteredDate = new Date(date);

        if (exp.length > 0) {
            exp.some((item) => {
                if (item.U_Ex_ID != newForm.U_Ex_ID) {
                    const FromDate = new Date(item.From);
                    enteredDate.setHours(0, 0, 0, 0);
                    FromDate.setHours(0, 0, 0, 0);
                    let ToDate;
                    if (item.to === "Present") {
                        ToDate = new Date();
                        ToDate.setHours(0, 0, 0, 0);
                    } else {
                        ToDate = new Date(item.to);
                        ToDate.setHours(0, 0, 0, 0);
                    }
                    console.log("entered Date : ", enteredDate);
                    console.log("From Date", FromDate);
                    console.log("To Date", ToDate);

                    if (enteredDate >= FromDate && enteredDate <= ToDate) {
                        flag = false;
                        return true;
                    } else {
                        flag = true;
                    }
                }
            });
        } else {
            return true;
        }

        return flag;
    };

    const checkToValidDate = (date) => {
        let flag = false;
        const enteredDate = new Date(date);

        if (exp.length > 0) {
            exp.some((item) => {
                if (item.U_Ex_ID != newForm.U_Ex_ID) {

                    const FromDate = new Date(item.From);
                    const enterFrom = new Date(newForm.From);
                    enterFrom.setHours(0, 0, 0, 0)
                    enteredDate.setHours(0, 0, 0, 0);
                    FromDate.setHours(0, 0, 0, 0);
                    let ToDate;
                    if (item.to === "Present") {
                        ToDate = new Date();
                        ToDate.setHours(0, 0, 0, 0);
                    } else {
                        ToDate = new Date(item.to);
                        ToDate.setHours(0, 0, 0, 0);
                    }

                    if (enterFrom <= FromDate && (enteredDate >= ToDate || (enteredDate >= FromDate && enteredDate <= ToDate))) {
                        flag = false;
                        return true;
                    } else {
                        flag = true;
                    }
                }
            });
        } else {
            return true;
        }
        return flag;
    }

    console.log('errorform', errorForm)

    return (
        <div id="myModal" className="model-open">
            <div className="modal-content" style={{ padding: 0 }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
                    <span style={{ color: "#46c294", fontWeight: "600" }}>Update Experience</span>
                    <span className="close" onClick={() => CloseModel()}>
                        &times;
                    </span>
                </div>
                <div style={{ padding: "0 40px", marginTop: "30px" }}>
                    <form
                        className="model-field exp-form"
                        onSubmit={handleEditsubModel}
                    >
                        <div>
                            <div>
                                {" "}
                                <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                    <div>
                                        <label htmlFor="Company">Company Name<span className="asterisk">*</span></label>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Company}</small>
                                        <input
                                            value={newForm.Company}
                                            type="text"
                                            name="Company"
                                            // placeholder={item.Company}
                                            onChange={(e) =>
                                                setNewForm({ ...newForm, Company: e.target.value })
                                                // setnewExp({ ...newExp, Company: e.target.value })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="Designation">Designation<span className="asterisk">*</span></label>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Designation}</small>
                                        <input
                                            value={newForm.Designation}
                                            type="text"
                                            name="Designation"
                                            // placeholder="Enter Designation of Your Designation"
                                            // placeholder={item?.Designation}
                                            onChange={(e) =>
                                                setNewForm({ ...newForm, Designation: e.target.value })
                                            }
                                        />
                                    </div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                    <div>
                                        <label htmlFor="Location">Location<span className="asterisk">*</span></label>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Location}</small>
                                        {/* <input
                                            value={newForm.Location}
                                            type="text"
                                            name="Location"
                                            placeholder="Enter your Company Location"
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (!inputValue.trim()) {
                                                    return setNewForm({ ...newForm, Location: "" });
                                                }
                                                setNewForm({ ...newForm, Location: inputValue });
                                            }}
                                        /> */}

                                        <Select
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: '100%',
                                                }),
                                            }}
                                            options={Country.getAllCountries()}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={
                                                Country.getAllCountries().find(
                                                    (country) => country.name === newForm.Location
                                                ) || null
                                            }
                                            onChange={(item) => {
                                                setNewForm({
                                                    ...newForm,
                                                    Location: item.name,
                                                });
                                            }}
                                        />

                                    </div>
                                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                        <div>
                                            <label htmlFor="From">From<span className="asterisk">*</span></label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.From}</small>
                                            <input
                                                value={newForm.From}
                                                type="date"
                                                name="From"
                                                // placeholder="Enter Joining  Year"
                                                // value={item?.From.toLocaleDateString('en-GB')}
                                                onChange={(e) => {
                                                    const enteredDate = e.target.value;
                                                    const currentDate = new Date().toISOString().split("T")[0]
                                                    const valid = checkValidDate(enteredDate)
                                                    if (valid) {
                                                        if (enteredDate > currentDate) {
                                                            message.warning("Can Not Select Future Date!")
                                                        }
                                                        else {
                                                            const extractedYear = enteredDate.split("-")[0].slice(0, 4);
                                                            setNewForm({ ...newForm, From: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                                        }
                                                    }
                                                    else {
                                                        message.warning("Date is already falls between previous Experience")
                                                    }
                                                }}
                                                min="1900"
                                                max="2099"
                                            />
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "column", marginRight: "6%" }}>
                                            <label htmlFor="to" style={{ display: "block" }}>To<span className="asterisk">*</span></label>
                                            <span style={{ display: 'flex', alignItems: 'center', gap: "6px", fontSize: '12px', marginTop: "6px" }}>
                                                <input
                                                    type="checkbox"
                                                    style={{ width: "auto", margin: 0, padding: 0 }}
                                                    checked={expireDatetoggle}
                                                    onChange={() => {
                                                        const valid = checkToValidDate(new Date());
                                                        if (valid) {
                                                            setExporeDatetoggle(!expireDatetoggle)
                                                            setErrorForm({ ...errorForm, to: "" })
                                                            setNewForm({ ...newForm, to: "Present" });
                                                        }
                                                        else {
                                                            message.warning("Date is already falls between previous Experience")
                                                        }
                                                    }}
                                                />Currently Employee</span>

                                            <input
                                                value={newForm.to}
                                                readOnly={expireDatetoggle}
                                                style={{ backgroundColor: expireDatetoggle ? "#eeeeee" : "" }}
                                                type="date"
                                                name="to"
                                                placeholder="Enter left Date"
                                                className={errorForm.to != "" ? "errorborder" : ""}
                                                onChange={(e) => {
                                                    const toDate = new Date(e.target.value);
                                                    const fromDate = new Date(newForm.From);
                                                    const today = new Date();
                                                    const valid = checkToValidDate(toDate);
                                                    // alert("valid "+valid)
                                                    if (valid) {
                                                        if (toDate > today) {
                                                            message.warning("Can Not Select Future Date")
                                                        }
                                                        else if (fromDate < toDate) {
                                                            setNewForm({ ...newForm, to: e.target.value });
                                                        } else {
                                                            message.warning("Invalid Entry")
                                                        }
                                                    }
                                                    else {
                                                        message.warning("Date is already falls between previous Experience")
                                                    }
                                                }}
                                                min="1900"
                                                max="2099"
                                            />
                                            <small style={{ color: "#ec1c24" }}>{errorForm.to}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="description">Description<span className="asterisk">*</span></label>
                                <small style={{ color: "#ec1c24" }}>{errorForm.Description}</small>
                                <textarea
                                    value={newForm.Description}
                                    name="Description"
                                    id="description"
                                    placeholder="Description"
                                    cols="30"
                                    rows="10"
                                    style={{ width: "98%" }}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (!inputValue.trim()) {
                                            return setNewForm({ ...newForm, Description: "" });
                                        }
                                        setNewForm({ ...newForm, Description: inputValue });
                                    }}
                                ></textarea>
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                            <input type="submit" className="btn" value={"Update"} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditWorkExp;
