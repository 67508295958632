import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MyContext } from "../Mycontext";
import axios from "axios";

export default function Product() {
    const { endPointUrl, Token, user } = useContext(MyContext);
    const navigate = useNavigate();
    const [product, setProduct] = useState([]);

    const getProducts = async () => {
        try {
            const { data } = await axios.get(endPointUrl + "/getProduct");
            if (data) {
                setProduct(data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getProducts()
    }, [])


    const showProducts = () => {
        if (product.length > 0) {
            return product.map((item) => {
                return (
                    <div className="Apart-points" style={{ display: 'block' }}>
                        <div >
                            <div className="aprt-containet-value" style={{ width: '100%', display: 'flex', gap: '20px' }}>
                                <div style={{ width: '20%', display: 'flex', alignItems: 'center' }}><img src={`${endPointUrl}/images/${item.Image}`} width="100%" /></div>

                                <div style={{ width: '80%', color: 'black', lineHeight: '2' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: "30px", cursor: 'pointer' }} onClick={() => navigate("/productInfo", { state: { item: item } })}>{item?.ProductName}</div>

                                    <div style={{ marginTop: '2%', cursor: 'pointer', color: '#606563' }} onClick={() => navigate("/productInfo", { state: { item: item } })}>{item?.PluginInfo}</div>

                                    <div style={{ marginTop: '2%', color: '#606563' }}>
                                        {item.Description}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: 'right', color: '#606563' }}><a onClick={() => navigate("/productInfo", { state: { item } })} style={{ cursor: 'pointer', textDecoration: 'under-line' }}>
                            <button className="mil_buton">More Info</button>
                        </a></div>
                    </div>
                )
            })
        }
        else {
            return (
                <div className="Apart-points">
                    <div>There is No Any Product Yet...</div>
                </div>
            )
        }
    }

    return (
        <div>
            <div className="HowItWorks">
                <div className="home-howitwork">
                    <Link to="/">Home </Link>/{" "}
                    <Link to="/freelancers">Product</Link>
                </div>

                <div className="Apart">
                    <h1>What makes a product valuable?</h1>
                    <p>
                        Successful products often address a specific problem or provide a unique solution that resonates with their target audience.
                    </p>
                </div>

                <div className="Apart-points">
                    <span className="aprt-containet-head">
                        List of Products
                    </span>
                </div>

                <div>{showProducts()}</div>

            </div>

        </div >)
}