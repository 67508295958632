import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { MyContext } from "../../../Mycontext";
import { MdOutlineCancel } from 'react-icons/md';
import editbutton from '../../../images/edit.png'
import deletebutton from '../../../images/delet2.png'
import { message } from 'antd';
import Selectant from '../../Jobs/Select';

function EditPortfolio({ Portfolio, getPortfolio, getPortfolioProfile }) {
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [showmodel, setshowModel] = useState(false);
    const [skillmaster, setskillmaster] = useState([]);
    const [preform, setPreform] = useState([]);
    const [File_Type, setFile_Type] = useState([])
    const [newForm, setNewForm] = useState({
        Title: Portfolio.Title || "",
        Description: Portfolio.Description || "",
        Type: Portfolio.Type || "",
        Completion_Date: Portfolio.Completion_Date.slice(0, 10) || "",
        Skills: Portfolio.Skills || "",
        Project_Link: Portfolio.Project_Link || "",
    });
    const [errorForm, setErrorForm] = useState({
        Title: "",
        Description: "",
        Type: "",
        Completion_Date: "",
        Skills: "",
        Project_Link: "",
        preform: "",
    });
    const [skillSelected, setSkillSelected] = useState([]);

    // Use a useEffect hook to update the Skills field in the form state
    useEffect(() => {
        const skillsString = skillSelected.join(", ");
        setNewForm({ ...newForm, Skills: skillsString });
    }, [skillSelected]);

    const handleskillsmaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push(item.Name));
        setskillmaster(a);
    };

    // Delete Portfolio
    const deletePortfolio = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/user/deletePortfolio", {
                Token,
                UserID: user.id,
                PF_Id: Portfolio.PF_Id
            });
            message.success("Portfolio Deleted");
            getPortfolio();
            getPortfolioProfile();
        } catch (err) {
            console.log(err);
        }
    }
    function getFileExtension(filename) {
        var a = filename?.split(".");
        if (a.length === 1 || (a[0] === "" && a.length === 2)) {
            return "";
        }
        return a.pop();
    }
    const handlefiles = () => {
        let arr = [];
        preform.map((file) => arr.push(getFileExtension(file.name)));
        setFile_Type(arr);
    }

    useEffect(() => {
        if (File_Type.length > 0) { uploadfiles() }
    }, [File_Type])


    const uploadfiles = async () => {

        const formdata = new FormData();
        preform.map((item) => formdata.append('File_Upload', item))

        try {
            const { data } = await axios.post(
                endPointUrl + "/user/docupload", formdata //Files array upload
            );

            try {
                await axios.post(endPointUrl + "/user/addportfolioDoc", {
                    Token,
                    PF_Id: Portfolio.PF_Id,
                    UserID: user.id,
                    File_Upload: data.filenames,
                    File_Type: File_Type
                });
                message.success("Portfolio Added")
                getPortfolio();
            } catch {
                console.log("upload url produce error");
            }
        } catch {
            console.log("error occur during upload docs");
        }
    }


    // Edit Portfolio
    const handleEditProtfolio = () => {
        const newFormErrors = {
            Title: "",
            Description: "",
            Type: "",
            Completion_Date: "",
            Skills: "",
            Project_Link: "",
            preform: "",
        };
        let showError = false;
        if (newForm.Title === "") {
            showError = true;
            newFormErrors.Title = 'Please fill out this field!';
        } else {
            newFormErrors.Title = '';
        }
        if (!newForm.Description || newForm.Description.trim() === "") {
            showError = true;
            newFormErrors.Description = 'Please fill out this field!';
          } else if (newForm.Description.length < 250) {
            showError = true;
            newFormErrors.Description = 'Description must be at least 250 characters!';
          } else if (newForm.Description.length > 1000) {
            showError = true;
            newFormErrors.Description = 'Description cannot exceed 1000 characters!';
          } else {
            newFormErrors.Description = '';
          }
        // if (newForm.Description === "") {
        //     showError = true;
        //     newFormErrors.Description = 'Please fill out this field!';
        // }
        // else if (newForm.Description.length < 250) {
        //     newFormErrors.Description = 'Description minimum 250 character allowed!';
        // }
        // else if (newForm.Description.length > 1000) {
        //     newFormErrors.Description = 'Description maximum 1000 character allowed!';
        // }
        // else {
        //     newFormErrors.Description = '';
        // }
        if (newForm.Type === "") {
            showError = true;
            newFormErrors.Type = 'Please fill out this field!';
        }
        else {
            newFormErrors.Type = "";
        }
        if (newForm.Completion_Date === "") {
            showError = true;
            newFormErrors.Completion_Date = 'Please fill out this field!';
        } else {
            newFormErrors.Completion_Date = '';
        }
        if (newForm.Skills === "") {
            showError = true;
            newFormErrors.Skills = 'Please fill out this field!';
        } else {
            newFormErrors.Skills = '';
        }
        if (preform.length < 1) {
            showError = true;
            newFormErrors.preform = 'Please select a file!';
        } else {
            newFormErrors.preform = '';
        }
        // if (newForm.Project_Link === "") {
        //     showError = true;
        //     newFormErrors.Project_Link = 'Please fill out this field!';
        // } else {
        //     newFormErrors.Project_Link = '';
        // }
        setErrorForm(newFormErrors);
        if (!showError) {
            editPortfolio()
        }
    }

    const editPortfolio = async () => {
        try {
            await axios.post(endPointUrl + "/user/updatePortfolio", {
                Token,
                PF_Id: Portfolio.PF_Id,
                Title: newForm.Title,
                Description: newForm.Description,
                Type: newForm.Type,
                Completion_Date: newForm.Completion_Date || Portfolio.Completion_Date,
                Skills: newForm.Skills,
                Project_Link: newForm.Project_Link,

            });
            setshowModel(false);
            getPortfolio();
            handlefiles();

            // Handle the response as needed
        } catch (err) {
            console.log(err);
        }
    }


    const handleshowform = async () => {
        handleskillsmaster();
        setPreform(Portfolio.docs)
        setSkillSelected(Portfolio?.Skills?.split(', '))
        setshowModel(true);
    }

    return (
        <>
            {/* <div className="edit-icons">
                <img id="edit-btn" src={editbutton} alt="edit" onClick={handleshowform} />
                <img id="del-btn" src={deletebutton} alt="delete" onClick={deletePortfolio} />
            </div> */}

<div className="edit-icons" style={{position:"relative"}} >
                      <img id="edit-btn" src={editbutton} alt=""  style={{cursor:"pointer"}} onClick={handleshowform} />
                      <span className="hover-text" >Edit</span>
                      </div>
                      


                      <div className="edit-icons mt-4" style={{position:"relative", marginTop:"10px"}} >
                      <img id="del-btn" src={deletebutton} alt="" onClick={() => {
                        if(window.confirm("Are you sure you want to delete?")){

                            deletePortfolio()}}} />
                      <span className="hover-text">Delete</span>
                      </div>
            <div id="myModal" className={showmodel ? "model-open" : "model"}>
                <div className="modal-content" style={{ padding: "0" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
                        <span style={{ color: "#46c294", fontWeight: "600" }}>Update Portfolio</span>
                        <span className="close" onClick={(() => setshowModel(false))}>
                            &times;
                        </span>
                    </div>
                    <div style={{ padding: "0 40px", margin: "30px 0" }}>
                        <form className="model-field exp-form"
                            encType="multipart/form-data"
                            onSubmit={(e) => {
                                e.preventDefault();

                                handleEditProtfolio();
                            }}>
                            <div style={{ paddingInlineEnd: "20px" }}>
                                <div>
                                    {" "}
                                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                        <div>
                                            <label htmlFor="title">Title<span className="asterisk">*</span></label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Title}</small>
                                            <input
                                                style={{ marginInlineEnd: "12%" }}
                                                type="text"
                                                name="title"
                                                value={newForm.Title}
                                                placeholder='Enter title'
                                                className={errorForm.Title != "" ? "errorborder" : ""}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value.slice(0, 45);
                                                    if (!inputValue.trim()) {
                                                        return setNewForm({ ...newForm, Title: "" });
                                                    }
                                                    setNewForm({ ...newForm, Title: inputValue });
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="Completion_Date">Date<span className="asterisk">*</span></label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Completion_Date}</small>
                                            <input
                                                value={newForm.Completion_Date}
                                                type="date"
                                                name="Completion_Date"
                                                placeholder="Enter Completion_Date"
                                                min="1900"
                                                max="2099"
                                                className={errorForm.Completion_Date != "" ? "errorborder" : ""}
                                                onChange={(e) => {
                                                    const enteredDate = e.target.value;
                                                    const extractedYear = enteredDate.split("-")[0].slice(0, 4);
                                                    setNewForm({ ...newForm, Completion_Date: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                        <div>
                                            <label htmlFor="project_type">Project Type<span className="asterisk">*</span></label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Type}</small>
                                            <select
                                                style={{ marginInlineEnd: "12%", marginBottom: "0", width: "100%" }}
                                                name="project_type"
                                                value={newForm.Type}
                                                placeholder="Enter Completion_Date"
                                                className={errorForm.Type != "" ? "errorborder" : ""}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (!inputValue.trim()) {
                                                        return setNewForm({ ...newForm, Type: "" });
                                                    }
                                                    setNewForm({ ...newForm, Type: inputValue });
                                                }}
                                            >
                                                <option value="">Project type</option>
                                                <option value="Website">Website</option>
                                                <option value="Mobile App">Mobile App</option>
                                                <option value="ERP">ERP</option>
                                                <option value="SPA">SPA</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor="SkillsRequired">Skills<span className="asterisk">*</span></label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Skills}</small>
                                            <Selectant
                                                label={"Enter skills"}
                                                options={skillmaster}
                                                setselected={setSkillSelected}
                                                selected={skillSelected}
                                                className={errorForm.Skills.length < 1 ? "errorborder" : ""}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <div >
                                            <label htmlFor="descrption" style={{ display: "block" }}>Description<span className="asterisk">*</span></label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Description}</small>
                                            <textarea
                                                style={{ width: "98%" }}
                                                name="description"
                                                id=""
                                                cols="30"
                                                rows="3"
                                                className={errorForm.Description != "" ? "errorborder" : ""}
                                                maxLength={500}
                                                value={newForm.Description}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (!inputValue.trim()) {
                                                        return setNewForm({ ...newForm, Description: "" });
                                                    }
                                                    setNewForm({ ...newForm, Description: inputValue });
                                                }}
                                                placeholder='Enter description'></textarea>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                            <div>
                                                <label htmlFor="file" style={{ display: "block" }}>Files<span className="asterisk">* </span><span style={{ fontSize: "10px", fontWeight: 300, color: "#9b9b9b" }}>maximum size 500 kb and format(jpg/jpeg/png/pdf)</span></label>
                                                <small style={{ color: "#ec1c24" }}>{errorForm.preform}</small>
                                                <input
                                                    style={{ marginInlineEnd: "12%" }}
                                                    type="file"
                                                    name="File_Upload"
                                                    className={errorForm.preform != "" ? "errorborder" : ""}
                                                    multiple
                                                    onChange={(e) => {
                                                        const files = e.target.files;
                                                        const currentFile = e.target.files[0];
                                                        const allowedFileTypes = [
                                                            "image/jpeg",
                                                            "image/png",
                                                            "image/jpg",
                                                            "application/pdf",
                                                            // "application/msword",
                                                            // "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                        ];
                                                        if ((currentFile.size / 1024) <= 500) {
                                                            if (files) {
                                                                const filteredFiles = Array.from(files).filter(file => (
                                                                    allowedFileTypes.includes(file.type) && file.size / (1024 * 1024) <= 10
                                                                ));

                                                                if (filteredFiles.length > 0) {
                                                                    setPreform(filteredFiles);
                                                                } else {
                                                                    setPreform([])
                                                                    e.target.value = null
                                                                    message.warning("Files format and size mismatch");
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            e.target.value = ''; // Reset the input value
                                                            message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="Project_Link">Project Link</label>
                                                <small style={{ color: "#ec1c24" }}>{errorForm.Project_Link}</small>
                                                <input
                                                    style={{ marginInlineEnd: "12%" }}
                                                    type="text"
                                                    name="Project_Link"
                                                    value={newForm.Project_Link}
                                                    className={errorForm.Project_Link != "" ? "errorborder" : ""}
                                                    placeholder='Enter Link'
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        if (!inputValue.trim()) {
                                                            return setNewForm({ ...newForm, Project_Link: "" });
                                                        }
                                                        setNewForm({ ...newForm, Project_Link: inputValue });
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        <div>
                                            {preform && preform.length > 0 && (
                                                <div>
                                                    <h4>Selected Files:</h4>
                                                    <ul>
                                                        {Array.from(preform).map((file, index) => (
                                                            <li key={index} style={{ display: "grid", gridTemplateColumns: "30% 60%", alignItems: "center", gap: "30px" }}>
                                                                <span style={{ overflowWrap: "break-word" }}><strong>Type:</strong> {file.File_Type || file.type}</span>
                                                                <span style={{ overflowWrap: "break-word" }}> <strong>Name:</strong> {file.File_Upload || file.name}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                    <input type="submit" className="btn" value={"Submit"} />
                                </div>
                            </div>
                        </form></div>

                </div>
            </div>
        </>
    )
}

export default EditPortfolio;