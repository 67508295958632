import React, { useContext, useEffect, useState, useRef } from "react";
import Profile from "../../images/freelancer.png";
import { MyContext } from "../../Mycontext";
import moment from "moment/moment";
import axios from "axios";
import { Spin, message } from "antd";
import EditJob from "../Jobs/EditJob";
import DocumentUpload from "../Jobs/DocumentUpload";
import dashboardImg from "../../images/img.png";
import TotalAndAvgBidding from "../Jobs/totalAndAvgBidding";
import BiddingCard from "../Jobs/biddingCard";
import { Collapse } from "antd";
import ProgressBar from "../ProgressBar"
import { useNavigate } from "react-router";
import TotalSpent from "./employer-payment/TotalSpent";
import { motion } from "framer-motion";
import { IoArrowBackSharp } from 'react-icons/io5';
import img from '../../images/whiiteveryfied.png';
import { AiFillStar, AiFillDelete } from 'react-icons/ai';
import placeholder from '../../images/layer1.svg'
import Invite from "./Invite";
import Swal from "sweetalert2";

const { Panel } = Collapse;

function EmployerJobProfile() {
  const navigate = useNavigate();
  const { endPointUrl, Token, user, socket, setShowSummary } = useContext(MyContext);
  const [detailview, setdetailview] = useState(false);
  const [employeview, setemployeview] = useState(true);
  const [counter, setCounter] = useState(0);
  const [bgColor, setbgColor] = useState('');
  const [detailjob, setdetailjob] = useState({});
  const [detailStatus, setdetailStatus] = useState({});
  const [showjobs, setShowjobs] = useState({
    My_Jobs: true,
  })
  const [jobs, setjobs] = useState([]);
  const [Onjobs, setOnjobs] = useState([]);
  const [Onstatus, setOnstatus] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [activejob, setActivejob] = useState(0);
  const [activejobIn, setActivejobIn] = useState(0);
  const [Onactivejob, setOnActivejob] = useState(0);
  const [MessageActive, setMessageActive] = useState(0);
  const [messageJobs, setMessageJobs] = useState([]);
  const [totalbits, settotalbits] = useState();
  const [projectMilestones, setProjectMilestones] = useState([]);
  const [chatJobID, setChatJobID] = useState([]);

  let [Receiver, setReceiver] = useState();
  const [alljobs, setAlljobs] = useState({
    Published: [],
    Draft: [],
    Approved: [],
    Archived: [],
  });
  const [Status, setStatus] = useState({
    progress: [],
    closed: [],
  });
  const [ongoing, setOngoing] = useState({
    InProgress: [],
    Closed: [],
  });
  const [loading, setloading] = useState(true);

  function handleReceiver(ID) {
    setReceiver(ID);
  };


  const handleStatusToggle = async (JobId, isActive) => {
    try {
      await axios.post(endPointUrl + "/user/UpdateJobs", {
        Token,
        JobID: JobId,
        Status: "Published",
        IsActive: isActive
      });
      getjobs();
      handlejobClickIn(0);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };


  async function getFreelancer(id) {
    const { data } = await axios.post(endPointUrl + "/getUserById", {
      id,
      Token,
    })

    return data.data
  }

  const getChatUsers = async (res) => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/chatusers", {
        Token,
        UserID: user.id,
        Type: user?.Type,
      })

      const jobForMsg = [];

      res.map((item) => {
        console.log('item. jobid : ', item.JobID);
        data.data.map((val) => {
          console.log('val.lastChat.JobID', val.lastChat.JobID)
          if (val.lastChat.JobID == item.JobID) {
            jobForMsg.push({ freelancer: val.user, ...item });
          }
        })
      });

      setMessageJobs([...messageJobs, ...jobForMsg]);

      console.log('chatdata message : ', jobForMsg);
    } catch (error) {
      console.log("error while get chat users")
    }
  }


  const getOngoingjobs = async (activeTab) => {
    try {
      const { data } = await axios.post(endPointUrl + "/getProjectsforEmployer", {
        Token,
        Awarded_By_ID: user.id,
      });

      // getChatUsers(data.data);



      let progress = data.data.filter((item) => {
        return item?.Project_status === "in_Progress" || item?.Project_status === "Semi_Completed"
      });

      let InProgress = progress.map((item) => {
        return { ...item?.jobs, Project_status: "in_Progress", Projectmile: item?.Projectmile, total_amount: item?.total_amount };
      })

      let closed = data.data.filter((item) => {
        return item.Project_status === "Completed"
      });

      let Closed = closed.map((item) => {
        return item?.jobs;
      })
      setStatus({ progress: progress, closed: closed });
      setOngoing({ InProgress: InProgress, Closed: Closed });
      setOnjobs(Onactivejob ? Closed : InProgress);
      setOnstatus(activeTab ? closed : progress);
    } catch (error) {
      console.log("Error While Gets Project For Employer", error)
    }
  }


  function checkExpiry(Title, expiry, JobID) {
    const currentDate = new Date();

    const expiryDate = new Date(expiry);

    if (currentDate > expiryDate) {
      socket.emit("Job_Expiry", {
        Type: "Project",
        FromID: user.id,
        ToID: user.id,
        Message: `Last Date ${expiryDate} for a job post ${Title} is expired`,
        IsRead: "false",
        ItemID: JobID
      })
    }
    else {
    }
  }

  const getjobs = async (activeTab) => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/getJobsById", {
        Employer_ID: user.id,
        Token,
      });

      getChatUsers(data.data);

      let closeJob = data.data.filter((item) => {
        let now = moment();
        let jobCreatedAt = moment(item?.createdAt);
        let diffInMonths = now.diff(jobCreatedAt, "months");
        // Only store jobs that are within the last 2 months
        return diffInMonths <= 2 && (item?.Status != "Published" && item?.Status != "Draft");
      });

      let archivedJob = data.data.filter((item) => {
        let now = moment();
        let jobCreatedAt = moment(item?.createdAt);
        let diffInMonths = now.diff(jobCreatedAt, "months");

        // Only store jobs that are within the last 2 months
        return diffInMonths > 2 && (item?.Status != "Published" && item?.Status != "Draft");
      });

      let Draft = data.data.filter((item) => {
        return item.Status === "Draft";
      });
      let Published = data.data.filter((item) => {
        let now = moment();
        let jobCreatedAt = moment(item?.Expiry);
        let diffInDays = now.diff(jobCreatedAt, "days");
        // return item.Status === "Published" && item.IsActive && diffInDays <= 0;
        return item.Status === "Published" && diffInDays <= 0;
      });
      setjobs(activeTab === 2 ? closeJob : activeTab === 1 ? Draft : Published);
      // setActivejob(activeTab ? activeTab : 0);
      setActivejobIn(activeTab ? activeTab : 0)
      setAlljobs({ Published: Published, Draft: Draft, Approved: closeJob, Archived: archivedJob });
      setloading(false);
    } catch (err) {
      console.log(err);
    }

  };
  const publishJob = async (JobID, Status) => {
    try {
      await axios.post(endPointUrl + "/user/UpdateJobs", {
        Token,
        IsActive: true,
        Status: Status,
        JobID: JobID,
      });
      getjobs();
      setjobs(alljobs.Published);
      handlejobClickIn(0);
      message.success(`Your Job is Now ${Status}`);
    } catch {
      message.error("Some Problem Occur Doing Publish");
    }
  };
  const UpdateStatus = async (P_ID) => {
    try {
      await axios.post(endPointUrl + "/admin/UpdateinProgresstoClosedproject", {
        Token,
        P_ID: P_ID,
      });
      getOngoingjobs();
      setdetailview(!detailview);
      message.success('Project Closed');
    } catch {
      message.error("error");
    }
  };

  const labelStyles = {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    color: '#5E5E5E',
    fontSize: '12px',
    margin: '3px'
  }

  const progress = () => {
    (setCounter((counter) => counter = 0));
    (user?.About) ? (setCounter((counter) => counter + 17)) : (null),
      (user?.Verified_Profile == "Verified") ? (setCounter((counter) => counter + 20)) : (null),
      (user?.Photo) ? (setCounter((counter) => counter + 10)) : (null),
      // (user?.Title) ? (setCounter((counter) => counter + 5)) : (null),

      (user?.Name) ? (setCounter((counter) => counter + 10)) : (null),
      (user?.Address) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Gender) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.Mobile) ? (setCounter((counter) => counter + 10)) : (null),

      (user?.City) ? (setCounter((counter) => counter + 7)) : (null),
      (user?.Email) ? (setCounter((counter) => counter + 7)) : (null),
      (user?.Language?.length > 0) ? (setCounter((counter) => counter + 5)) : (null),
      (user?.State) ? (setCounter((counter) => counter + 2)) : (null),
      (user?.Country) ? (setCounter((counter) => counter + 2)) : (null)
  }

  const gradient = () => {
    (counter < 25) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg,rgba(7,178,116,1), #00ffff)')) : (
      (counter < 70) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1), #00ffff, #c4fdff)'))
        : (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1) 25%, #00ffff 63%, rgba(51,154,250,1) 90%)'))
    )
  }

  useEffect(() => {
    gradient();
  }, [counter])
  useEffect(() => {
    progress();
  }, [user])

  const Analytics = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/analytics", {
        id: user.id,
        Token
      });
      setAnalytics(data.data);
    } catch {
      message.error("Error while fetching analytics")
    }
  };
  const handlejobClick = (buttonIndex) => {
    setActivejob(buttonIndex);
  };
  const handlejobClickIn = (buttonIndex) => {
    setActivejobIn(buttonIndex);
  };
  const handleOnjobClick = (buttonIndex) => {
    setOnActivejob(buttonIndex);
  };
  const handleMessageClick = (buttonIndex) => {
    setMessageActive(buttonIndex);
  };
  useEffect(() => {
    (setCounter((counter) => counter = 0));
    progress();
    Analytics();
    getjobs();
    getOngoingjobs();
  }, []);


  function getLanguage() {
    let languages;
    try {
      languages = JSON.parse(user.Language);
    } catch (error) {
      return (<span>{user?.Language}</span>);
    }
    return (
      <span>
        {languages?.Language1}
        {languages?.Language2 ? `, ${languages.Language2}` : ''}
        {languages?.Language3 ? `, ${languages.Language3}` : ''}
        {languages?.Language4 ? `, ${languages.Language4}` : ''}
        {languages?.Language5 ? `, ${languages.Language5}` : ''}
      </span>
    );
  }


  const handleDate = (item) => {
    let createdAt = new Date(item.createdAt);
    let day = createdAt.getUTCDate().toString().padStart(2, '0'); // Get day (padded with leading zero if needed)
    let month = (createdAt.getUTCMonth() + 1).toString().padStart(2, '0'); // Get month (January is 0)
    let year = createdAt.getUTCFullYear().toString().slice(-2); // Get last two digits of the year
    let DATE = `${day}/${month}/${year}`;

    //Set Time
    let hours = createdAt.getHours();
    let minutes = createdAt.getMinutes();
    let seconds = createdAt.getSeconds();
    let TIME = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');

    //Set TimeZone
    let TimeZone = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).formatToParts(createdAt).find(part => part.type === 'timeZoneName').value
    return <span>{DATE}, {TIME}, {TimeZone}</span>;
  }

  const handleDeleteJob = async (JobID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This job will be permanently deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await axios.post(endPointUrl + "/user/deleteJobs", { JobID, Token })
          if (data.status) {
            setjobs(alljobs.Published);
            getjobs();
            handlejobClickIn(0);
            message.success("Job Deleted Successfully")
          }
        } catch (error) {
          message.warning("Something Went Wrong!")
          console.log("Erro While Delete Job ", error);
        }
      }
    });
  }

  return (
    <>
      <div className="jobProfileContainer">
        <div className="compo">
          {!detailview ? (
            <div className="welcomeConatiner">
              <div>
                <span className="welcome-date">
                  {moment().format("dddd") + ", " + moment().format("MMM Do")}
                </span>
                <span className="welcomeName">
                  {new Date().getHours() < 12 ? " Good Morning, " : new Date().getHours() >= 12 && new Date().getHours() < 16 ? "Good Afternoon, " : "Good Evening, "}
                  <span style={{ color: "#07B274" }}>
                    {user?.Name} {user?.Last_Name ? user?.Last_Name.charAt(0)?.toUpperCase() + "." : null}
                  </span>
                </span>
              </div>
              <div>
                <img src={dashboardImg} alt="" />
              </div>
            </div>
          ) : null}
          {detailview ? (
            <>
              <Collapse defaultActiveKey={["1"]}>
                <Panel
                  header={
                    <div >
                      <IoArrowBackSharp style={{
                        backgroundColor: "white",
                        borderRadius: "50px",
                        stroke: "#073b",
                        padding: "2px",
                        margin: " -8px 10px -8px 0"
                      }}
                        onClick={() => {
                          setdetailview(!detailview);
                          setemployeview(!employeview);
                          getjobs(activejob);
                        }}
                      />
                      <span>
                        {detailjob?.Title}
                      </span>
                    </div>
                  }
                  key="1" showArrow={false} style={{ fontFamily: "poppins" }}>
                  {/* <h2 style={{ color: "#07b274", textDecoration: "underline" }}>
              {}
            </h2> */}

                  <div
                    style={{ color: "#606563" }}
                    dangerouslySetInnerHTML={{ __html: detailjob?.Description }}
                  ></div>
                </Panel>
              </Collapse>
              {employeview ? (detailview ? <BiddingCard change={handleReceiver} JobID={detailjob.JobID} Show={employeview} /> : null)
                : <BiddingCard change={handleReceiver} JobID={detailjob.JobID} Show={employeview} />
              }

            </>
          ) : (
            <>

              <div className="jobContainer">
                <div className="job-container-header">
                  <span>Dashboard</span>
                </div>
                <div className="jobMatch">

                  <span
                    style={
                      activejob === 0
                        ? {
                          color: "#07b273",
                          fontWeight: "800",
                        }
                        : null
                    }
                    onClick={() => {
                      setShowjobs({ My_Jobs: true })
                      handlejobClick(0);
                      setShowSummary(false);
                    }}
                  >
                    My Jobs
                    {
                      activejob === 0
                        ? (
                          <motion.div className="underline" layoutId="underline" />
                        )
                        : null
                    }
                  </span>
                  <span
                    style={
                      activejob === 1
                        ? {
                          color: "#07b273",
                          fontWeight: "800",
                        }
                        : null
                    }
                    onClick={() => {
                      setShowjobs({ My_Projects: true })
                      handlejobClick(1);
                      setShowSummary(true);
                    }}
                  >
                    My Projects
                    {
                      activejob === 1
                        ? (

                          <motion.div className="underline" layoutId="underline" />
                        )
                        : null
                    }
                  </span>
                  <span
                    style={
                      activejob === 2
                        ? {
                          color: "#07b273",
                          fontWeight: "800",
                        }
                        : null
                    }
                    onClick={() => {
                      setShowjobs({ My_Messages: true });
                      handlejobClick(2);
                    }}
                  >
                    My Messages
                    {
                      activejob === 2
                        ? (
                          <motion.div className="underline" layoutId="underline" />
                        )
                        : null
                    }
                  </span>
                  <span
                    style={
                      activejob === 3
                        ? {
                          color: "#07b273",
                          fontWeight: "800",
                        }
                        : null
                    }
                    onClick={() => {
                      setShowjobs({ My_Payments: true });
                      handlejobClick(3);
                    }}
                  >
                    My Payments
                    {
                      activejob === 3
                        ? (
                          <motion.div className="underline" layoutId="underline" />
                        )
                        : null
                    }
                  </span>
                </div>
                <hr style={{ margin: "-1px -20px" }} />

                {!loading ?
                  <div>
                    {showjobs.My_Jobs ?
                      <div style={{ display: "grid" }}>
                        <div className="jobMatch-inside">

                          <span
                            style={
                              activejobIn === 0
                                ? {
                                  color: "#07b273",
                                  fontWeight: "600"
                                }
                                : null
                            }
                            onClick={() => {
                              setjobs(alljobs.Published);
                              handlejobClickIn(0);
                            }}
                          >
                            Active
                          </span>
                          <p>|</p>
                          <span
                            style={
                              activejobIn === 1
                                ? {
                                  color: "#07b273",
                                  fontWeight: "600"
                                }
                                : null
                            }
                            onClick={() => {
                              setjobs(alljobs.Draft);
                              handlejobClickIn(1);
                            }}
                          >
                            Draft
                          </span>
                          <p>|</p>
                          <span
                            style={
                              activejobIn === 2
                                ? {
                                  color: "#07b273",
                                  fontWeight: "600"
                                }
                                : null
                            }
                            onClick={() => {
                              setjobs(alljobs.Approved);
                              handlejobClickIn(2);
                            }}
                          >
                            Closed
                          </span>
                          <p>|</p>
                          <span
                            style={
                              activejobIn === 3
                                ? {
                                  color: "#07b273",
                                  fontWeight: "600"
                                }
                                : null
                            }
                            onClick={() => {
                              setjobs(alljobs.Archived);
                              handlejobClickIn(3);
                            }}
                          >
                            Archived
                          </span>
                        </div>
                        <div style={{ maxHeight: "1100px", overflowY: "scroll" }}>
                          {jobs?.length > 0 ? (
                            jobs.map((item, index) => (
                              <div key={index}>
                                {" "}
                                <div className="current-jobs">
                                  <div style={{ maxWidth: '100%' }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                                      <div style={{ width: "75%" }}>
                                        <span
                                          className="job-name jobName_Dasbord"
                                          onClick={() => {
                                            window.scrollTo(0, 0);
                                            navigate(`/detail-view-jobE/${item?.JobID}`)
                                          }}
                                        >
                                          {item?.Title}

                                        </span>

                                        <p dangerouslySetInnerHTML={{ __html: item?.Description?.slice(0, 300) + '...' }} className="jobName_Dasbord_Paragrap"></p>
                                      </div>

                                      <div>
                                        {item.IsActive ? (
                                          <TotalAndAvgBidding JobID={item.JobID} />
                                        ) : null}

                                        <div style={{ marginTop: '5%' }}>
                                          {item?.Status === "Draft" || item?.Status === "Approved" || item?.Status === "Closed" ? <></> : <Invite detailjob={item} JobID={item.JobID} JobTitle={item.Title} />}
                                        </div>
                                      </div>

                                      {!item.IsActive && item.Status === "Draft" ? (
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            <EditJob
                                              Job={item}
                                              getjobs={getjobs}
                                              button={false}
                                            />
                                            <AiFillDelete
                                              className="btn"
                                              onClick={() => handleDeleteJob(item.JobID)}
                                              style={{
                                                padding: "4px",
                                                margin: "2px 4px",
                                                background: "red",
                                              }}
                                            >
                                              Delete
                                            </AiFillDelete>
                                          </div>
                                          <div style={{ marginTop: '20%' }}>
                                            <button
                                              className="btn"
                                              style={{ background: "#abab22de", padding: '10px 20px' }}
                                              onClick={() => publishJob(item.JobID, "Published")}
                                            >
                                              Publish
                                            </button>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>


                                    <div className="current-tags">
                                      {item.SkillsRequired?.split(",").map((val, index) =>
                                        val.length > 0 ? <span key={index}>{val}</span> : null
                                      )}
                                    </div>

                                    <div className="bid-status">
                                      {/* <span style={{ background: "none", color: "gray" }} >
                                      Proposals : 0
                                    </span>
                                    <span style={{ background: "none", color: "gray" }}>
                                      Messages : 0
                                    </span> 
                                       <span style={{ background: "none", color: "gray" }}>
                                      Hired Users : 0
                                    </span> */}
                                      <span style={{ background: "none", color: "gray" }}>
                                        {handleDate(item)}
                                      </span>


                                      <span style={{ background: "none", color: "gray", marginLeft: '1%' }}>
                                        <div className="radio-inputs-user">
                                          {item?.Status === "Draft" ? <></> :
                                            <label className="radio">
                                              <input
                                                disabled={item?.Status === "Closed" || item?.Status === "Approved"}
                                                type="radio"
                                                name={`radio-${index}`}
                                                checked={item.IsActive == 1} // Assuming "true" represents "Active"
                                                onChange={() => handleStatusToggle(item.JobID, 1)} // Set status to "Active"
                                              />
                                              <span className="status">Active</span>
                                            </label>
                                          }
                                          <label className="radio">
                                            <input
                                              disabled={item?.Status === "Closed" || item?.Status === "Approved"}
                                              type="radio"
                                              name={`radio-${index}`}
                                              checked={item.IsActive == 0} // Assuming "false" represents "Inactive"
                                              onChange={() => handleStatusToggle(item.JobID, 0)} // Set status to "Inactive"
                                            />
                                            <span className="status">InActive</span>
                                          </label>
                                        </div>
                                      </span>
                                    </div>
                                  </div>

                                </div>
                                <hr />
                              </div>
                            ))
                          ) : (
                            <div className="jobs-placeholder">
                              <img src={placeholder} alt="placeholder" />
                              <h3>
                                OOPS its Empty
                              </h3>
                              <p>Looks like you dont have any active project.</p>
                              <button onClick={() => {
                                window.scroll(0, 0)
                                navigate("/job-creation")
                              }
                              } className="buton">Post Jobs</button>

                            </div>
                          )}
                        </div>


                      </div> : null}
                    {showjobs.My_Projects ?
                      <div style={{ display: "grid" }}>
                        <div className="jobMatch-inside">

                          <span
                            style={
                              Onactivejob === 0
                                ? {
                                  color: "#07b273",
                                  fontWeight: "600"
                                }
                                : null
                            }
                            onClick={() => {
                              setOnjobs(ongoing.InProgress);
                              setOnstatus(Status.progress);
                              handleOnjobClick(0);
                            }}
                          >
                            Ongoing
                          </span>
                          <p>|</p>
                          <span
                            style={
                              Onactivejob === 1
                                ? {
                                  color: "#07b273",
                                  fontWeight: "600"
                                }
                                : null
                            }
                            onClick={() => {
                              setOnjobs(ongoing.Closed);
                              setOnstatus(Status.closed);
                              handleOnjobClick(1);
                            }}
                          >
                            Closed
                          </span>

                        </div>


                        {!loading ? (
                          Onjobs.length > 0 ? (
                            Onjobs.map((item, index) => (
                              <div key={index}>
                                {" "}
                                <div className="current-jobs">
                                  <div style={{ position: 'relative' }}>
                                    <span
                                      className="job-name"
                                      onClick={() => {
                                        window.scrollTo(0, 0);
                                        checkExpiry(item.Title, item.Expiry, item.JobID)
                                        navigate(`/detail-view-jobE/${item.JobID}`)
                                      }}
                                    >
                                      {item.Title}
                                    </span>
                                    <span style={{ position: 'absolute', right: 0 }}><span style={{ fontSize: '14px', color: "#07B273" }}>{item.ProjectType}</span></span>
                                    <div style={{ display: "flex", width: "100%" }}>
                                      <p style={{ wordBreak: "break-word", width: "70%" }} dangerouslySetInnerHTML={{ __html: item?.Description?.slice(0, 300) + '...' }}></p>
                                      <hr style={{ margin: "0.5rem" }} />
                                      <div style={{ width: "30%" }}>
                                        {item?.ProjectType === "Hourly" ? "" :
                                          <span>
                                            <div><strong>${item?.total_amount}</strong> <span style={{ fontSize: '14px', color: "#606563" }}>(Total Amount)</span></div>
                                          </span>}

                                        {item.ProjectType == "Fixed" &&
                                          item.Projectmile.map((item, index) => {
                                            return (
                                              <div>
                                                <span style={{ fontSize: '14px', fontWeight: 500 }}>Milestone{index + 1}: </span>
                                                <span style={{ fontSize: '12px', color: "#606563" }}>{item.MilstoneStatus}</span>
                                              </div>)
                                          })
                                        }
                                      </div>
                                    </div>



                                    {/* <p dangerouslySetInnerHTML={{ __html: item.Description?.slice(0, 300) + '...' }}></p>
                                    <div className="current-tags">
                                      {item.SkillsRequired?.split(",").map((val) =>
                                        val.length > 0 ? <span key={val}>{val}</span> : null
                                      )}
                                    </div>

                                    {item?.ProjectType === "Fixed" &&
                                      <div>
                                        {item.Projectmile?.map((milestone, i) => {
                                          if (milestone.MilstoneStatus == "Pending" || milestone.MilstoneStatus == "Up for Review") {
                                            return <i key={i}>{`Milestone #${i + 1} is active`}</i>;
                                          }
                                          return null;
                                        })}
                                      </div>
                                    } */}

                                    <div>
                                      <span style={{ background: "none", color: "gray", fontSize: '12px' }}>
                                        {handleDate(item)}
                                      </span>
                                    </div>

                                    <div id="keywords">
                                      {(item?.Keywords ? item.Keywords?.split(",") : []).map(
                                        (skill, skillIndex) => {
                                          return skill.length > 0 ? (
                                            <span key={skillIndex} style={{ background: "none", color: "gray" }}> #{skill}</span>
                                          ) : null;
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            ))
                          ) : (
                            <div className="jobs-placeholder">
                              <img src={placeholder} alt="placeholder" />
                              <h3>
                                OOPS its Empty
                              </h3>
                              <p>Looks like you dont have any active project.</p>
                              <button onClick={() => navigate("/jobs")} className="buton">Find Jobs</button>

                            </div>
                          )
                        ) : (
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <Spin />
                          </div>
                        )}

                      </div> : null}
                    {showjobs.My_Messages ?
                      <div style={{ display: "grid" }}>
                        {/*  <span
                            style={
                              MessageActive === 0
                                ? {
                                  color: "#07b273",
                                  fontWeight: "600"
                                }
                                : null
                            }
                            onClick={() => {
                              handleMessageClick(0);
                            }}
                          >
                            Inbox
                          </span>
                          <p>|</p>
                          <span
                            style={
                              MessageActive === 1
                                ? {
                                  color: "#07b273",
                                  fontWeight: "600"
                                }
                                : null
                            }
                            onClick={() => {

                              handleMessageClick(1);
                            }}
                          >
                            Sent
                          </span>
                          */}

                        {messageJobs.length > 0 ? (
                          messageJobs.map((item, index) => (
                            <div key={index}>
                              <div className="current-jobs" >
                                <div>
                                  <span
                                    className="job-name"
                                    onClick={() => {
                                      window.scrollTo(0, 0);
                                      navigate(`/chat-user`)
                                    }}
                                  >
                                    {item?.freelancer?.Name} {item?.freelancer?.Last_Name} <div style={{ fontWeight: 400, display: 'inline-block', fontSize: '12px' }}>{`(${item?.Title})`}</div>
                                  </span>
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))
                        ) : (
                          <div className="jobs-placeholder">
                            <img src={placeholder} alt="placeholder" />
                            <h3>
                              OOPS its Empty
                            </h3>
                            <p>Looks like you dont have any active project.</p>
                            <button onClick={() => Navigate("/jobs")} className="buton">Find Jobs</button>

                          </div>
                        )}
                      </div> : null}
                    {showjobs.My_Payments ?
                      <TotalSpent /> : null}
                  </div> :
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spin />
                  </div>
                }
              </div>
            </>
          )}
        </div>
        {!detailview ? (
          <div className="compo2">
            <div className="profileConatiner" style={{ position: "relative" }}>
              <div className="profile-details" style={{ position: "relative" }}>
                <img src={img} alt="" style={{ filter: user?.Verified_Profile == "Verified" ? "none" : "brightness(1)", width: "28px", height: "auto", marginLeft: "auto", position: "absolute", top: "8px", right: "8px" }} />

                <img
                  src={
                    user?.Photo === "" || user?.Photo == null
                      ? Profile
                      : `${endPointUrl}/images/${user?.Photo}`
                  }
                  alt=""
                />
                <span className="profilename">{user?.Name} {user?.Last_Name}
                </span>

                <span className="rating" style={{ paddingLeft: "6px" }}
                  onClick={() => navigate(`/user-profile/reviews/${user.Name + "-" + user.id}`)}
                >
                  <AiFillStar className="featuredfreelancer-rating-star" />
                  {user?.avg_Rating ? user?.avg_Rating : 0} <p>({user?.no_of_reviews ? user?.no_of_reviews : 0} Reviews)</p></span>
              </div>
              <div className="profile-complete" style={{ borderRadius: "0" }}>
                <span style={labelStyles}><span style={{ fontWeight: "600", color: "black", marginRight: "3px" }}>{counter}% </span> Profile complete</span>
                <div className="progress">
                  <ProgressBar bgColor={bgColor} completed={counter} />
                </div>
                <div className="profile-btn" onClick={() => {
                  user.Type == "Employer"
                    ? navigate("/employer-profile")
                    : navigate("/login-user-profile");
                }}>
                  <button style={{ borderRadius: "3px" }}>Complete Profile</button>
                </div>
              </div>
              <hr />

              <div className="rate-card" style={{ borderRadius: "0 0 20px 20px", wordBreak: "break-word" }}>
                <h2 style={{ marginBlock: "auto" }}>About Employer</h2>
                <p style={{ fontSize: "12px", color: "#606060" }}>{user?.About}</p>
                <div>

                </div>
                <hr />
                <div className="user-details">
                  <p>Job Post</p>
                  <span>{analytics.totalprojects}</span>
                </div>
                <div className="user-details">
                  <p>Completed Projects</p>
                  <span>{analytics.completedProject}</span>
                </div>
                <div className="user-details">
                  <p>Ongoing Projects</p>
                  <span>{analytics.ongoingprojects}</span>
                </div>
                <div className="user-details">
                  <p>Close Projects</p>
                  <span>{analytics.expiredprojects}</span>
                </div>
                <div className="user-details">
                  <p>Average project Payment</p>
                  <span>${analytics?.completedProject > 0 ? (Math.floor(analytics?.revenuegenerated / (analytics?.completedProject + analytics?.ongoingprojects))) ? (Math.floor(analytics?.revenuegenerated / (analytics?.completedProject + analytics?.ongoingprojects))) : 0 : 0}</span>
                </div>
                <div className="user-details">
                  <p>Total Spent</p>
                  <span>${analytics?.revenuegenerated}</span>
                </div>
                <div className="user-details">
                  <p>Language</p>
                  <span>{getLanguage()}
                  </span>
                </div>
                <div className="user-details" >
                  <p>Location</p>
                  <span>{user?.City} {user?.City ? "," : null} {user?.Country}</span>
                </div>
                {/* <div className="user-details">
                  <p>Funds in Escrow </p>
                  <span>$${analytics.fundsInEscrow}</span>
                </div> */}
                <div className="user-details">
                  <p>Gender</p>
                  <span>{user?.Gender}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="sideComponent">
            <div className="see-other-post">
              {detailjob?.IsActive && detailjob?.Status === "Published" ? (
                <button
                  className="btn"
                  style={{ background: "#434343" }}
                  onClick={() => publishJob(detailjob?.JobID, "Draft")}
                >
                  Unpublish
                </button>
              ) : null}
              {detailStatus?.Project_status === "in_Progress" && detailjob?.Status != ("Published" || "Draft" || "Expired") ? (
                <button
                  className="btn"
                  style={{ background: "#434343" }}
                  onClick={() => UpdateStatus(detailStatus?.P_ID)}
                >
                  Close Project
                </button>
              ) : null}

              {detailjob.Status === "Draft" ? (
                <>
                  <button
                    className="btn"
                    style={{ background: "#abab22de" }}
                    onClick={() => publishJob(detailjob?.JobID, "Published")}
                  >
                    Publish
                  </button>

                  {
                    <EditJob
                      button={true}
                      Job={detailjob}
                      getjobs={getjobs}
                      setdetailview={setdetailview}
                      handlejobClick={handlejobClick}
                    />
                  }
                </>
              ) : null}
              {/* <button
                className="btn"
               
              >
                Close
              </button> */}
            </div>
            <hr style={{ width: "100%" }} />
            <div className="uploadDocs">
              <h4 style={{ margin: "0 0 5px 0" }}>
                {detailjob?.IsActive ? "Documents" : "Upload Documents"}{" "}
              </h4>
              {detailjob?.IsActive ? (
                <div className="uploadtags">
                  {(detailjob?.Documents
                    ? detailjob?.Documents?.split(",")
                    : []
                  ).map((tag, index) => (
                    <span key={index}>
                      <a
                        href={`${endPointUrl}/images/${tag}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {tag}
                      </a>
                    </span>
                  ))}
                </div>
              ) : (
                <DocumentUpload detailjob={detailjob} getjobs={getjobs} />
              )}
            </div>
            <hr style={{ width: "100%", background: "" }} />
            <div className="row-view">
              <div>
                <h4 style={{ margin: "5px 0 5px 0" }}>Budget Details</h4>
                <small>{detailjob?.Budget_From}-</small>
                <small>{detailjob?.Budget_To}/</small>
                <small>{detailjob?.Budget_Type}</small>
              </div>
              <div>
                {" "}
                <h4 style={{ margin: "5px 0 5px 0" }}>Job Type</h4>
                <small>{detailjob?.Type}</small>
              </div>
            </div>
            <hr style={{ width: "100%", background: "" }} />
            <div>
              {" "}
              <h4 style={{ margin: "20px 0 5px 0" }}>Skills</h4>
              <div className="skillview">
                {(detailjob?.SkillsRequired)?.split(",")?.map((item) => (
                  <span>{item}</span>
                ))}
              </div>
            </div>
            <div>
              <h4 style={{ margin: "20px 0 5px 0" }}>Technology</h4>
              <div className="skillview">
                {(detailjob?.Technology)?.split(",").map((item) => (
                  <span>{item}</span>
                ))}
              </div>
            </div>

            <div>
              <h4 style={{ margin: "20px 0 5px 0" }}>Category</h4>
              <div className="skillview">
                {(detailjob?.Category)?.split(",").map((item) => (
                  <span>{item}</span>
                ))}
              </div>
            </div>
            {/* <div>
              <h4 style={{ margin: "20px 0 5px 0" }}>KeyWords</h4>
              <div className="skillview">
                {detailjob?.Keywords?.split(",").map((item) => (
                  <span>{item}</span>
                ))}
              </div>
            </div> */}
          </div>
        )}

      </div >
      {/* <Messages Receiver={Receiver} /> */}
    </>
  );
}

export default EmployerJobProfile;
