import React, { useContext, useState, useEffect } from "react";
import logo from "../../images/Logo.png";
import loginImg from "../../images/loginImg.png";
import face from "../../images/face.png";
import linke from "../../images/linke.png";
import google from "../../images/google.png";
import employeer from "../../images/emplyeer.png";
import freelancer from "../../images/freelancer.png";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import OtpInput from "react-otp-input";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { MyContext } from "../../Mycontext";
import FacebookLogin from 'react-facebook-login';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { LoginSocialFacebook, LoginSocialLinkedin } from 'reactjs-social-login'

function Signup() {
  const { endPointUrl, Token } = useContext(MyContext);
  const [showpage, setshowPage] = useState({
    page1: true,
    page2: false,
    page3: false,
  });
  const [showpass, setshowpass] = useState(false);
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Name, setName] = useState("");
  const [Last_Name, setLast_Name] = useState("");
  const [Type, setType] = useState("Employer");
  const [Terms, setTerms] = useState(false);
  const [useFullmail, setuseFullmail] = useState(false);
  const [otp, setotp] = useState("");
  const [userotp, updateUserotp] = useState();
  const [Status, setStatus] = useState("pending");
  const Navigate = useNavigate();

  const signup = useGoogleLogin({
    onSuccess: (tokenResponse) =>
      storeResultofGooglesignUp(tokenResponse.access_token),
  });

  const storeResultofGooglesignUp = async (access_token) => {
    const { data } = await axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${access_token}`
    );
    setEmail(data.email);
    setName(data.name);
    setshowPage({ page1: false, page2: true, page3: false });
    setStatus("Active");
  };

  const proceedfirst = async () => {
    if (Name.length < 2 && Email.length < 9) {
      message.warning("Enter Name or Email Correctly");
    } else {
      try {
        const { data } = await axios.post(endPointUrl + "/user/isUseralreadyExist", {
          Token,
          Email: Email,
        });
        if (data.message === "User already exist") {
          message.warning("User with this Email already exists")
        } else { setshowPage({ page1: false, page2: true, page3: false }) }
      } catch {
        message.error("Something went wrong");
      }
      // setshowPage({ page1: false, page2: true, page3: false });
    }
  };
  const proceedSecond = async () => {
    if (Password.length < 6 || Terms == false || useFullmail == false) {
      message.warning("Enter password more than 6 words Or accept the terms");
    } else if (Status == "Active") {
      try {
        const { data } = await axios.post(endPointUrl, {
          Name,
          Last_Name,
          Email,
          Password,
          Type,
          // Status,
          Token
        });
        if (data?.status) {
          message.success("signup Successfully");
          Navigate("../login");
        }
        else {
          message.warning(data?.message)
        }
      } catch (err) {
        message.error("Something went wrong");
      }
    } else {
      try {
        const { data } = await axios.post(endPointUrl + "/user/generateotp", {
          Email, Token, Name
        });
        // setotp(data.otp);
        setshowPage({ page1: false, page2: false, page3: true });
      } catch (err) {
        message.error("Something went wrong");
        setshowPage({ page1: true, page2: false, page3: false });
        message.warning("email is not correct");
      }
    }
  };
  const verifythree = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/verifiedotp", {
        Name,
        Last_Name,
        Email,
        Token,
        userotp,
        Type
      })
      if (data.status) {
        await axios.post(endPointUrl + "/", {
          Name,
          Last_Name,
          Email,
          Password,
          Type,
          Token
        });
        message.success("signup Successfully");
        return Navigate("../login");
      } else {
        message.error("incorrect otp");
      }
    } catch (err) {
      message.error("Incorrect Otp");
      console.log("Error", err);
    }
  };

  const handleChangeEmail = async () => {
    try {
      await axios.post(endPointUrl + "/user/changeEmail", { Email })
      setEmail('');
      setPassword('')
      setshowPage({ page1: true, page2: false, page3: false });
    } catch (error) {
      message.warning("Email Not Change")
    }
  }

  const onsubmit = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/", {
        Name,
        Last_Name,
        Email,
        Password,
        Type,
        Token
      });
      message.success("Logged in successfully");
      Navigate("./login");
    } catch (err) {
      message.error("Something went wrong");
    }
  };
  const resendotp = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/generateotp", {
        Email, Token
      });
      // setotp(data.otp);
      message.success('otp send successfully')
    }
    catch {
      message.error('otp is not sendable retry!!')
    }
  }

  // const { linkedInLogin } = useLinkedIn({
  //   clientId: '775yrq8oaylow1',
  //   redirectUri: `${window.location.origin}`,
  //   scope:"",
  //   onSuccess: (code) => {
  //     console.log(code);
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });

  const linkedInLogin = () => {
    const clientId = '775yrq8oaylow1';
    const redirectUri = 'http://localhost:3000/linkedin-callback';
    // const redirectUri = 'http://localhost:3000';
    const scope = 'email profile'; 

    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}`;

    window.location.href = authUrl;
  };


  return (
    <div className="login">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="digilanxer logo" />
        </Link>
      </div>
      <div className="login-component">
        <div className="login-img">
          <div className="grow-with-digilanxer">
            Grow with <span style={{ color: "#07b274" }}>digilanxer</span>
          </div>
          <div className="image">
            <img src={loginImg} alt="" />
            <strong>99% Customer Satisfaction</strong>
            {/* <span>Based on paid invoices</span> */}
          </div>
        </div>
        <div className="login-details">
          <h2>Sign Up</h2>

          {showpage.page1 && (
            <>
              <div className="facebooklogin">
                <div>
                  {/* <FacebookLogin
                  appId="364263563207221"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  textButton=""
                  icon={<img src={face} alt="Facebook Login" />}
                  cssClass="facebook-login-button"
                /> */}
                  <LoginSocialFacebook
                    appId="524475356661088"
                    onResolve={(response) => {
                      setEmail(response.data.email);
                      setName(response.data.name);
                      setshowPage({ page1: false, page2: true, page3: false });
                      setStatus("Active");
                    }}
                    onReject={(error) => {
                      console.log('error', error);
                    }}
                  >
                    <img src={face} alt="Facebook Login" />
                  </LoginSocialFacebook>
                </div>
                <div>
                  {/* <LoginSocialLinkedin
                    client_id="775yrq8oaylow1"
                    client_secret="3Mea5QQ9rYVwCBCL"
                    redirect_uri="http://localhost:3000"
                    // onLoginStart={onLoginStart}
                    scope="email profile"
                    response_type = 'code'
                    onResolve={({ provider, data }) => {
                      console.log('linkdln login', data);
                    }}
                    onReject={(err) => {
                      console.log('linkedln eroro', err)
                    }}
                  > */}
                  {/* </LoginSocialLinkedin> */}
                </div>
                <div><img src={google} alt="" onClick={() => signup()} /></div>
              </div>
              <div className="or-connect">OR Connect With</div>
              <div className="loginemail">
                <input
                  type="text"
                  name="Name"
                  placeholder="First Name"
                  value={Name}
                  onChange={(e) => {
                    const inputValue = e.target.value.trim();
                    const firstWord = inputValue.split(' ')[0];
                    setName(firstWord)
                  }}
                />
              </div>
              <div className="loginemail">
                <input
                  type="text"
                  name="Last_Name"
                  placeholder="Last Name"
                  value={Last_Name}
                  onChange={(e) => {
                    const inputValue = e.target.value.trim();
                    const firstWord = inputValue.split(' ')[0];
                    setLast_Name(firstWord)
                  }
                  }
                />
              </div>
              <div className="loginemail password">
                <input
                  type="email"
                  name="Email"
                  placeholder="Email Address"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="btn loginBtn" onClick={proceedfirst}>
                Proceed
              </div>
              <p style={{ textAlign: "center" }}>
                Already have an account?{" "}
                <span
                  style={{ color: "#07b274", cursor: "pointer" }}
                  onClick={() => Navigate("../login")}
                >
                  {" "}
                  Log In
                </span>
              </p>
            </>
          )}

          {showpage.page2 && (
            <>
              {" "}
              <div className="selectAccountType">SELECT AN ACCOUNT TYPE</div>
              <div className="type">
                <div
                  className={Type == "Employer" ? "typeSelect" : ""}
                  onClick={() => setType("Employer")}
                >
                  <img src={employeer} alt="employer" />
                  <strong>Employer</strong>
                  <span>(I want to Hire)</span>
                </div>
                <div
                  className={Type == "Freelancer" ? "typeSelect" : ""}
                  onClick={() => setType("Freelancer")}>
                  <img src={freelancer} alt="freelancer" />
                  <strong>Freelancer</strong>
                  <span>(I am looking for work)</span>
                </div>
              </div>
              <div className="setpassword">SET PASSWORD</div>
              <div className="loginemail password">
                <input
                  type={`${showpass ? "text" : "password"}`}
                  name="Password"
                  placeholder="password"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span onClick={() => setshowpass(!showpass)}>
                  {showpass ? "hide" : "show"}
                </span>
              </div>
              <div className="login-forgot">
                <span className="checkbox">
                  <input
                    type="checkbox"
                    checked={Terms}
                    onChange={() => setTerms(!Terms)} />
                  I agree to the Privacy Policy Terms of Service & IP Policy
                </span>
              </div>
              <div className="login-forgot">
                <span className="checkbox">
                  <input
                    type="checkbox"
                    checked={useFullmail}
                    onChange={() => setuseFullmail(!useFullmail)} />
                  Send me useful emails to help me get the most out of
                  digilanxer.com
                </span>
              </div>
              <div className="button">
                <button
                  className="btn-outline"
                  onClick={() =>
                    setshowPage({ page1: true, page2: false, page3: false })
                  }>
                  Back
                </button>
                <button className="btn" onClick={proceedSecond}>
                  Proceed
                </button>
              </div>
              <p style={{ textAlign: "center" }}>
                Already have an account?{" "}
                <span
                  style={{ color: "#07b274", cursor: "pointer" }}
                  onClick={() => Navigate("../login")}
                >
                  {" "}
                  Log In
                </span>
              </p>{" "}
            </>
          )}

          {showpage.page3 && (
            <>
              <div className="selectAccountType">VERIFY EMAIL ADDRESSS</div>
              <div className="code-text">
                Please enter the 4-digit code sent to your email
              </div>
              <div className="input-code">
                <OtpInput
                  value={userotp}
                  onChange={updateUserotp}
                  numInputs={4}
                  renderInput={(props) => <input {...props} />}
                />
              </div>

              <div className="resend-code">
                <span onClick={resendotp}>Resend Code</span>
                <span onClick={handleChangeEmail}>Change Email</span>
              </div>
              <div className="button verify">
                <button
                  className="btn-outline"
                  onClick={() =>
                    setshowPage({ page1: false, page2: true, page3: false })
                  }>
                  Back
                </button>
                <button className="btn" onClick={verifythree}>
                  Verify
                </button>
              </div>
              <p style={{ textAlign: "center" }}>
                Already have an account?{" "}
                <span
                  style={{ color: "#07b274", cursor: "pointer" }}
                  onClick={() => Navigate("../login")}
                >
                  {" "}
                  Log In
                </span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Signup;
