import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { MyContext } from "../../Mycontext";
import moment from "moment";
import { useNavigate } from "react-router";
import Master from "./Master";
import { message } from "antd";
import { AiOutlineMessage } from "react-icons/ai";

function BiddingUserInformation({ disputeStatus, change, item, Show, JobID, JobTitle, ProjectType }) {
  const { Token, endPointUrl, showMessg, setshowMessg, showchat, setJobsId, setShowchat, Ruser, setRUser, socket, user } = useContext(MyContext);
  const [showmodel, setshowModel] = useState(false);
  const [bidder, setbidder] = useState({});
  const [Count, setCount] = useState(0);
  const [milestone, setmilestone] = useState([]);
  const [showRejectBox, setRejectBox] = useState(false)
  const [rejectReason, setRejectReason] = useState('')
  const [invited, setInvited] = useState([]);

  const Navigate = useNavigate();

  function handlechange(id) {
    if (showMessg) {
      setRUser(null)
      setShowchat(!showchat)
    } else {
      setRUser(bidder)
      setShowchat(!showchat)
    }
    setJobsId(JobID)
    setshowMessg(!showMessg)
    change(bidder);
  }

  const getuserData = async (id) => {
    const { data } = await axios.post(endPointUrl + "/getUserById", {
      Token,
      id,
    });
    setbidder(data.data);

    const result = await axios.post(endPointUrl + "/project/getMilestoneforBid", {
      Token,
      Bid_ID: item.Bid_ID,
    });

    if (result.data.milestones.length !== 0) {
      setmilestone(result.data.milestones);
    } else {

      const result = await axios.post(endPointUrl + "/user/getMilestoneforBid", {
        Token,
        Bid_ID: item.Bid_ID,
      });
      setmilestone(result.data.milestones);
    }
  };
  const getCount = () => {
    for (let index = 0; index < milestone.length; index++) {
      const item = milestone[index];
      if (item.MilstoneStatus === "Up for Review") {
        setCount((Count) => Count + 1);
      }
    }
  };
  useEffect(() => {
    setCount((Count) => Count = 0)
    getCount()
  }, [milestone]);

  const handleExpModel = async () => {
    if (item.View_Status === false) {
      await axios.post(endPointUrl + "/user/updateBid", { Token, Bid_ID: item.Bid_ID, View_Status: true });
    }
    setshowModel(true);
    document.body.classList.add("modal-open");
  };
  const CloseModel = () => {
    setshowModel(false);
    document.body.classList.remove("modal-open");
  };

  function extractFirst100Words(htmlString) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    const textContent = tempElement.textContent || tempElement.innerText || "";
    const words = textContent.trim().split(/\s+/).slice(0, 30);
    const first100Words = words.join(" ");

    return first100Words;
  };

  const handleApprovedReject = async (status) => {
    try {
      if (status === "Reject") {
        if (rejectReason.length === 0) {
          message.warning("Reason for Rejection is Required!")
        }
        else {
          await axios.post(endPointUrl + "/user/updateBid", { Token, Bid_ID: item.Bid_ID, Bid_Status: status, Reason: rejectReason })
          message.success(`Bid ${status} successfully`)
          socket.emit("Job_Expiry", {
            Type: "Bid_Reject",
            FromID: user.id,
            ToID: item.UserID,
            Message: `Your Bid is rejected for job ${JobTitle}`,
            IsRead: "false",
            ItemID: item.JobID
          })
          CloseModel();
          window.location.reload(false);
        }
      }
      else {
        await axios.post(endPointUrl + "/user/updateBid", { Token, Bid_ID: item.Bid_ID, Bid_Status: status })
        message.success(`Bid ${status} successfully`)
        socket.emit("Job_Expiry", {
          Type: "Bid_Reject",
          FromID: user.id,
          ToID: item.UserID,
          Message: `Your Bid is rejected for job ${JobTitle}`,
          IsRead: "false",
          ItemID: item.JobID
        })
        CloseModel();
        window.location.reload(false);

      }

    } catch {
      message.error("Some occur on Approved/Rejection");
    }
  }
  useEffect(() => {
    getuserData(item.UserID);
    // console.log(item.Bid_ID);
  }, [item]);


  const getInvitationForJob = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/getInvitationByJobID", { JobID, Token })
      if (data.status) {
        const value = data.data.map((item) => {
          return item['User.id']
        })

        setInvited(value);
      }
    } catch (error) {
      console.log("Error while check bidder is invited or not ", error);
    }
  }


  useEffect(() => {
    getInvitationForJob();
  }, [])

  const checkInvited = (id) => {
    if (invited.length > 0)
      return invited.includes(id)
  }

  return (
    <div>
      {Show ?
        <>
          <div
            className="current-jobs"
            style={{ cursor: "pointer", marginTop: '0px' }}
          >
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img onClick={() => handleExpModel()} src={require("../../images/emplyeer.png")} alt="" style={{ width: '70px', marginRight: '15px', marginBottom: '10px' }} />
                <div>
                  <span
                    onClick={() => handleExpModel()}
                    className="job-name"
                    // onClick={() => {
                    //   setdetailview(!detailview);
                    //   setdetailjob(item);
                    //   window.scrollTo(0, 0);
                    // }}
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                  >
                    {bidder.Name}
                  </span>
                  <div className="jobContainer_header">
                    <p style={{ margin: "0 " }}
                      onClick={() => handlechange(bidder)}
                    >message<AiOutlineMessage /></p>
                  </div>

                  <div className="current-tags">
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).map((val, index) => {
                      if (index < 5) {
                        return val.length > 0 ? <span style={{ marginInlineStart: "0" }}>{val}</span> : null
                      }
                    })}
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? "+" : ""}
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? (bidder?.Skills ? bidder.Skills.split(",") : []).length - 5 : ""}

                  </div>
                </div>
              </div>
              {item?.Reason ?
                <p>Reason : {item?.Reason}</p>
                : <p>{extractFirst100Words(item.ShortDescription || item.Proposal)}...</p>}

              {/* <div id="keywords">
                        {(item?.Keywords ? item.Keywords.split(",") : []).map(
                          (skill) => {
                            return skill.length > 0 ? (
                              <span> #{skill}</span>
                            ) : null;
                          }
                        )}
                      </div> */}
            </div>
            <div>
              <div>
                <div className="avg-bite">
                  <strong>$ {item.BidAmount}</strong>
                </div>
                {ProjectType === "Hourly" ? <></> : <span className="total-bits"> {milestone.length} milestone</span>}
                <div className="total-bits">{moment(item.Bid_date).format("DD-MM-YYYY")}</div>
                <div style={{ color: "#07b273" }}>{checkInvited(bidder.id) ? "Invited" : ""}</div>
              </div>
            </div>
          </div>
          <div
            id="myModal"
            className={showmodel ? "model-open" : "model"}
            style={{ overflow: "scroll" }}
          >
            <div className="modal-content" style={{ color: "black" }}>
              <span className="close" onClick={CloseModel}>
                &times;
              </span>

              <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <div style={{ flex: '1' }}>
                  <img src={require("../../images/emplyeer.png")} alt="" />
                </div>
                <div style={{ flex: '5' }}>
                  <a
                    className="job-name"
                    // onClick={() => {
                    //   setdetailview(!detailview);
                    //   setdetailjob(item);
                    //   window.scrollTo(0, 0);
                    // }
                    href={`/user-profile/${bidder.Name}-${bidder.id}`}
                    style={{
                      marginLeft: "10px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                    }}
                    target="_blank" rel="noopener noreferrer"
                  // onClick={()=>Navigate(`/userProfile/${bidder.Name + "-" + bidder.id}`)}
                  >
                    {bidder.Name}
                  </a>
                  <div className="current-tags">
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).map((val, index) => {
                      if (index < 5) {
                        return val.length > 0 ? <span>{val}</span> : null
                      }
                    })}
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? "+" : ""}
                    {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? (bidder?.Skills ? bidder.Skills.split(",") : []).length - 5 : ""}

                  </div>
                </div>
                <div className="biddingInformation" style={{ flex: item.Duration != 0 ? '3' : '6' }}>
                  <div>
                    <span className="bidhead">Price</span>
                    <span id="bidheadValue">${item.BidAmount}</span>
                  </div>
                  {item.Duration != 0 ? <div>
                    <span className="bidhead">Duration</span>
                    <span id="bidheadDuration">
                      {item.Duration} {item?.DurationType === "Monthly" ? "Month" : item?.DurationType === "Weekly" ? "Week" : "Hour"}
                    </span>
                  </div> :
                    <>
                      <div>
                        <span className="bidhead">Payment Type</span>
                        <span id="bidheadDuration">
                          {item?.DurationType === "Monthly" ? "Monthly" : item?.DurationType === "Weekly" ? "Weekly" : "Hourly"}
                        </span>
                      </div>
                      <div>
                        <span className="bidhead">Work Hours</span>
                        <span style={{ fontSize: '20px', fontWeight: "bold" }}>
                          {item.MinHours}-{item.MaxHours} hrs / Week
                          {/* {item.MinHours}-{item.MaxHours}hrs /{item?.DurationType === "Monthly" ? "Month" : item?.DurationType === "Weekly" ? "Week" : "Hour"} */}
                        </span>
                      </div>
                    </>}
                </div>

                {item.Bid_Status === "Reject" || item.Bid_Status === "Closed" ?
                  <button className="mil_buton" style={{ backgroundColor: "brown", borderColor: "brown", borderRadius: "10px" }} onClick={() => { handleApprovedReject("Active") }}>
                    Back to Review</button>
                  :
                  item.Bid_Status === "Approved" ? <div className="biddingButton" style={{ flex: '2' }}>
                    <button style={{ background: "#cdefe3", color: "#07b273", fontSize: '14px', paddingLeft: '0px', paddingRight: '0px' }} onClick={() => handlechange(bidder)}>
                      Send Message</button>
                  </div> : <div className="biddingButton" style={{ flex: '2' }}>
                    <button style={{ background: "green" }}
                      // onClick={()=>{handleApprovedReject("Approved"),updateAppprovedReject("Approved")}}
                      onClick={() => { Navigate('/milestone-payment', { state: { milestone: milestone, item: item, freelancerID: bidder.id, JobTitle, ProjectType } }); CloseModel() }}
                    >
                      Accept</button>
                    {/* <button style={{ background: "#d70404" }} onClick={() => { handleApprovedReject("Reject") }}> */}
                    <button style={{ background: "#d70404" }} onClick={() => { setRejectBox(!showRejectBox) }}>
                      Reject</button>
                    <button style={{ background: "#cdefe3", color: "#07b273", fontSize: '14px', paddingLeft: '0px', paddingRight: '0px' }} onClick={() => handlechange(bidder)}>
                      Send Interview Request</button>
                  </div>
                }
              </div>


              {showRejectBox ? <div style={{ boxShadow: '0px 0px 5px 2px gray', padding: '30px', boxSizing: 'border-box' }}>
                <label>Reason For Rejection</label>
                <textarea
                  placeholder="Enter Reason For Rejection..."
                  style={{ width: "95%" }}
                  onChange={(event) => {
                    setRejectReason(event.target.value)
                  }}
                ></textarea>
                <div >
                  <button style={{ padding: '10px 20px', fontSize: '18px', borderRadius: '10px', outline: 'none', border: 'none', cursor: "pointer", color: 'white', background: '#07B273' }} onClick={() => handleApprovedReject("Reject")}>Submit</button>
                </div>
              </div> : <></>}

              {item.Reason ? <div>
                <b style={{ marginTop: "10px" }}>Reason</b>
                <div>{item.Reason}</div>
              </div> : <></>}
              <b style={{ marginTop: "10px" }}>Proposal</b>
              <span dangerouslySetInnerHTML={{ __html: item.Proposal }} style={{ wordBreak: "break-word" }} className="biddingProposal"></span>
              {item.Duration != 0 ?
                <Master milestone={milestone} /> : <></>
              }
            </div>
          </div>
        </>
        : <div className="modal-content" style={{ color: "black", width: "90%", margin: "0" }}>

          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div style={{ flex: '1' }}>
              <img src={require("../../images/emplyeer.png")} alt="" />
            </div>
            <div style={{ flex: '5' }}>
              <a
                className="job-name"
                // onClick={() => {
                //   setdetailview(!detailview);
                //   setdetailjob(item);
                //   window.scrollTo(0, 0);
                // }
                href={`/user-profile/${bidder.Name}-${bidder.id}`}
                style={{
                  marginLeft: "10px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
                target="_blank" rel="noopener noreferrer"
              // onClick={()=>Navigate(`/userProfile/${bidder.Name + "-" + bidder.id}`)}
              >
                {bidder.Name}
              </a>
              {/* message */}
              <div className="jobContainer_header">
                <p style={{ margin: "0 0 10px 10px" }}
                  onClick={handlechange}
                >message<AiOutlineMessage /></p>
              </div>

              <div className="current-tags">
                {(bidder?.Skills ? bidder.Skills.split(",") : []).map((val, index) => {
                  if (index < 5) {
                    return val.length > 0 ? <span>{val}</span> : null
                  }
                })}
                {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? "+" : ""}
                {(bidder?.Skills ? bidder.Skills.split(",") : []).length > 5 ? (bidder?.Skills ? bidder.Skills.split(",") : []).length - 5 : ""}

              </div>
            </div>
            <div className="biddingInformation" style={{ flex: item.Duration != 0 ? '3' : '6' }}>
              <div>
                <span className="bidhead">Price</span>
                <span id="bidheadValue">${item.BidAmount}</span>
              </div>
              <div>
                {/* <span className="bidhead">Duration</span>
                <span id="bidheadDuration">
                  {item.Duration + "-"}
                  {item.DurationType === "monthly" ? "month" : item.DurationType === "weekly" ? "week" : "hour"}
                </span> */}
                {item.Duration != 0 ? <div>
                  <span className="bidhead">Duration</span><br></br>
                  <span id="bidheadDuration">
                    {item.Duration + " "}
                    {item.DurationType === "Monthly" ? "Month" : item.DurationType === "Weekly" ? "Week" : item?.DurationType}
                  </span>
                </div> :
                  <div style={{ display: 'flex' }}>
                    <div>
                      <span className="bidhead">Payment Type</span><br></br>
                      <span id="bidheadDuration">
                        {item?.DurationType === "Monthly" ? "Monthly" : item?.DurationType === "Weekly" ? "Weekly" : "Hourly"}
                      </span>
                    </div>
                    <div style={{ marginLeft: '5%' }}>
                      <span className="bidhead">Work Hours</span><br></br>
                      <span style={{ fontSize: '20px', fontWeight: "bold" }}>
                        {item.MinHours}-{item.MaxHours} hrs / week
                        {/* {item?.DurationType === "Monthly" ? "Month" : item?.DurationType === "Weekly" ? "Week" : "Hour"} */}
                      </span>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

          <b style={{ marginTop: "10px" }}>Proposal</b>
          <span style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: item.Proposal }} className="biddingProposal"></span>
          <Master milestone={milestone} ProjectType={ProjectType} />
          {item.Bid_Status === "Awarded" || disputeStatus === "Pending" || disputeStatus === "InProgress" ? "" :
            <button className='mil_buton'
              style={{ marginInline: "27%", marginTop: "2em" }}
              onClick={() => {
                Navigate('/milestone-payment', { state: { milestone, item, JobTitle, ProjectType } });
              }}
            >{ProjectType === "Hourly" ? "Review Payment" : "Review Milestone Pyments"} ( {Count} )
            </button>}
        </div>}
    </div>
  );
};

export default BiddingUserInformation;